import React from 'react';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';
import { EditableColumn } from './Column/EditableColumn';
import { InputTypes } from '../form/InputTypes';
import { Icon } from '../icon/Icon';
import { DateHelpers } from '../../lib/Helpers/DateHelpers';
import { addHours } from 'date-fns';


const SortableHandler = SortableHandle((props) => props.handle);
SortableHandler.defaultProps = {
    handle: <div className='handle'><Icon icon='grip-vertical' /></div>
};

SortableHandler.propTypes = {
    handle: PropTypes.node
};


const dateTimeFormatter = (value) => {
    if (!value) return;
    if (window.user.timeZoneConverted == DateHelpers.offset()) {
        return new Date(value.indexOf('Z') > 0 ? value : value + 'Z').toLocaleString('en-Gb', { hour12: false });
    }

    var date = new Date(value);
    var converted = addHours(date, (window.user.timeZoneConverted)).toLocaleString('en-Gb', { hours12: false });
    return converted;

};

/*
const dateTimeFormatterLocal = (value) => {
    if (!value) return;
    var date = new Date(value.indexOf('Z') > 0 ? value : value + 'Z');
    date = DateHelpers.addHours(date, DateHelpers.timeDiff()).toISOString();
    return new Date(date).toLocaleString(navigator.language, { hour12: false });
};
*/


const dateFormatter = (value) => {
    if (window.user.timeZoneConverted == DateHelpers.offset()) {
        return new Date(value).toLocaleDateString(navigator.language);
    }
    var date = new Date(value);
    var converted = addHours(date, DateHelpers.offset() + (-1 * window.user.timeZoneConverted)).toLocaleDateString(navigator.language);
    return converted;
};

const jsonFormatter = (value) => {
    try {
        value = JSON.parse(value);
        return <div className='flex vertical'>
            {Object.keys(value).map(x => {
                return <div className='flex' key={x}><label>{x}</label><span>{value[x]}</span></div>;
            })}
        </div>;
    } catch (err) {
        //
    }
};

export const TableColumn = (props) => {
    const field = props.field;
    var column = null;
    const renderDefault = () => {
        switch (field.type) {
            case InputTypes.DateTime:
                {
                    const data = dateTimeFormatter(props.row[props.field.name]);
                    if (props.field.multiLine && data) {
                        const splitted = data.split(',');
                        if (splitted && splitted.length > 1) {
                            column = <div className='flex vertical'>
                                <div className='flex'>{splitted[0]}</div>
                                <div className='flex'>{splitted[1]}</div>
                            </div>;
                        } else {
                            column = data;
                        }
                    } else {
                        column = data;
                    }
                }
                break;
            case InputTypes.Number:
                column = props.row[props.field.name];
                break;
            case InputTypes.Date:
                column = dateFormatter(props.row[props.field.name]);
                break;
            case InputTypes.JSON:
                column = jsonFormatter(props.row[props.field.name]);
                break;
            case InputTypes.Bool:
                column = props.row[props.field.name] == true ? 'Yes' : 'No';
                break;
            case InputTypes.Select:
                try {
                    column = props.field.values[props.row[props.field.name]];
                } catch (err) {
                    console.log(err);
                }
                break;
            case InputTypes.Currency:
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                });

                column = formatter.format(props.row[props.field.name]);
                break;
            default:
                column = props.row[props.field.name];
        }
    };

    if (props.footer && field.renderFooter) {
        column = field.renderFooter(props.row[props.field.name], props.row, field, props.context);
    } else if (!props.footer && field.render) {
        column = field.render(props.row[props.field.name], props.row, field, props.context, props.tableContext);
    } else {
        renderDefault();
    }

    return (
        <td className={`flux-table-body-column column-${field.name.toLowerCase()}`}>
            {column}
        </td>
    );
};

TableColumn.propTypes = {
    field: PropTypes.object,
    row: PropTypes.object,
    footer: PropTypes.bool,
    context: PropTypes.object,
    tableContext: PropTypes.object
};


const TableRow = (props) => {
    const columns = props.model.fields.filter(x => !x.hidden).map((field, index) => {
        return field.editable ? (
            <EditableColumn key={index} field={field} {...props} />
        ) : (
            <TableColumn key={index} field={field} {...props} />
        );
    });

    const elements = (
        <React.Fragment>
            {props.options?.draggable?.enabled && (
                <td className='flux-table-body-column table-option-draggable-column'>
                    <SortableHandler />
                </td>
            )}
            {props.options?.selectable && (
                <td className='flux-table-body-column table-option-selectable-column' onClick={() => props.onSelect(props.row)}>
                    <Icon icon={props.row.__selected ? 'check-square' : 'square'} type='far' />
                </td>
            )}
            {columns}
            {props.options?.data && props.options.data?.delete?.enabled && (
                <td className='flux-table-body-column table-option-delete-column' onClick={() => {
                    props.onDelete(props.row);
                }} >
                    <Icon icon='times' />
                </td>
            )}
            {props.buttons && (
                <td className='flux-table-body-column flux-table-buttons'>{props.buttons(props.row, props.context, props.tableContext)}</td>
            )}
        </React.Fragment>
    );

    var className = props.className;
    if (props.model.onRenderClassName) {
        className = props.model.onRenderClassName(props.row, props.className, props.context);
    }

    if (props.onRenderRow) {
        return props.onRenderRow(props.row, elements, className, props.context);
    }

    return (
        <tr className={`flux-table-body-row ${className}`}>
            {elements}
        </tr>
    );
};

TableRow.defaultProps = {
    onDelete: () => { }
};

TableRow.propTypes = {
    className: PropTypes.string,
    model: PropTypes.object,
    row: PropTypes.object,
    options: PropTypes.object,
    context: PropTypes.object,
    tableContext: PropTypes.object,
    buttons: PropTypes.func,
    onRenderRow: PropTypes.func,
    onSelect: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func
};

export default TableRow;
