/* eslint-disable react/display-name */
import './_cashback-bonus-settings.scss';

import React, { useState } from 'react';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { FluxInput } from '../../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { Icon } from '../../../../../components/icon/Icon';
import { lang } from '../../../../../lib/Localization/language';
import { HorseTicketTypes, SportsTicketTypes } from '../../../../../lib/Enums/BettingEnums';


const recurringPeriods = {
    1: 'Daily',
    2: 'Weekly',
    3: 'Monthly',
    4: 'Every 1st and 16th day of Month',
};

const cashbackTypes = {
    1: 'Deposit Amount',
    2: 'Sport Net Losses',
    3: 'Sport Net Wins',
    // 4: 'Sport Net Losses/Wins (Combined)',
    5: 'Sport Turnover',
    6: 'Casino Net Losses',
    7: 'Casino Net Wins',
    // 8: 'Casino Net Losses/Wins (Combined)',
    9: 'Casino Turnover',
    10: 'Horse Net Losses',
    11: 'Horse Net Wins',
    // 12: 'Horse Net Losses/Wins (Combined)',
    13: 'Horse Turnover',
};

export const CashbackBonusSettings = (props) => {
    const { promotion, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(promotion.Configuration ?? {});
    const [focusedField, setFocusedField] = useState(null);

    const update = (field, value) => {
        setFocusedField(field.name);
     
        var copy = { ...promotion };
        copy.Configuration = configuration;
        if (field.type == InputTypes.Number) {
            value = parseFloat(value);
        }
        if (copy.Configuration[field.name] == value) {
            return;
        }
        if (field.name == 'cashbackType') {
            delete configuration['Award'];
        }
     
        copy.Configuration[field.name] = value;
        setConfiguration(copy.Configuration);
        onChange(copy);
    };

    return <flex className='vertical gap-20 cashback-bonus-settings'>
        <FluxCard className={'cashback-bonus-card'}>
            <flex className='vertical gap-20'>
                <fluxform className='gap-20 vertical'>
                    <rows className='gap-5 '>
                        <section>
                            <header>
                                <Icon icon='cogs' type='fa-light' /><span>{lang('General')}</span>
                            </header>
                            <flex className='grid form-columns-2'>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang('Recurring Period')}</span>
                                        </flex>
                                        <FluxInput
                                            field={{
                                                name: 'recurringPeriod',
                                                type: InputTypes.Select,
                                                required: true,
                                                value: [1],
                                                values: recurringPeriods
                                            }}
                                            onChange={(field, value) => update(field, value)}
                                            data={configuration}
                                            focused={focusedField == 'recurringPeriod'} />
                                        {(() => {
                                            switch (configuration?.recurringPeriod) {
                                                case '1':
                                                    return '(Every 00:00, operator local time)';
                                                case '2':
                                                    return '(Every Monday 00:00, operator local time)';
                                                case '3':
                                                    return '(Every 1st day of the month 00:00, operator local time)';
                                                default:
                                                    return '';
                                            }
                                        })()}
                                    </forminput>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang('Cashback Type')}</span>
                                        </flex>
                                        <FluxInput
                                            field={{
                                                name: 'cashbackType',
                                                type: InputTypes.Select,
                                                required: true,
                                                value: [1],
                                                values: cashbackTypes
                                            }}
                                            onChange={(field, value) => update(field, value)}
                                            data={configuration}
                                            focused={focusedField == 'cashbackType'} />
                                    </forminput>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang('Percentage')}</span>
                                        </flex>
                                        <FluxInput
                                            field={{
                                                name: 'percentage',
                                                type: InputTypes.Number,
                                                required: true,
                                            }}
                                            onChange={(field, value) => update(field, value)}
                                            data={configuration}
                                            focused={focusedField == 'percentage'} />
                                    </forminput>
                                </row>
                                <row>
                                    {configuration?.['cashbackType'] == 1 &&
                                        <forminput>
                                            <flex className='input-title fit-children'>
                                                <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                                <span>{lang('Minimum Deposit')}</span>
                                            </flex>
                                            <FluxInput
                                                field={{
                                                    name: 'minimumDeposit',
                                                    type: InputTypes.Number,
                                                    required: true,
                                                    placeholder: 'Min. Deposit amount required to qualify'
                                                }}
                                                onChange={(field, value) => update(field, value)}
                                                data={configuration}
                                                focused={focusedField == 'minimumDeposit'} />
                                        </forminput>
                                    }
                                    {(configuration?.['cashbackType'] == 2 ||
                                        configuration?.['cashbackType'] == 3 ||
                                        configuration?.['cashbackType'] == 4 ||
                                        configuration?.['cashbackType'] == 5) &&
                                        <forminput>
                                            <flex className='input-title fit-children'>
                                                <span>{lang('Sport Ticket Type')}</span>
                                            </flex>
                                            <FluxInput
                                                field={{
                                                    name: 'sportTicketType',
                                                    type: InputTypes.Select,
                                                    values: SportsTicketTypes,
                                                    nullable: true,
                                                    multiple: true,
                                                }}
                                                onChange={(field, value) => update(field, value)}
                                                data={configuration}
                                                focused={focusedField == 'sportTicketType'} />
                                        </forminput>
                                    }
                                    {(configuration?.['cashbackType'] == 10 ||
                                        configuration?.['cashbackType'] == 11 ||
                                        configuration?.['cashbackType'] == 12 ||
                                        configuration?.['cashbackType'] == 13) &&
                                        <forminput>
                                            <flex className='input-title fit-children'>
                                                <span>{lang('Horse Ticket Type')}</span>
                                            </flex>
                                            <FluxInput
                                                field={{
                                                    name: 'horseTicketType',
                                                    type: InputTypes.Select,
                                                    values: HorseTicketTypes,
                                                    nullable: true,
                                                    multiple: true,
                                                }}
                                                onChange={(field, value) => update(field, value)}
                                                data={configuration}
                                                focused={focusedField == 'horseTicketType'} />
                                        </forminput>
                                    }
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <Icon icon='asterisk' size='xs' type='fa-sharp fa-light' />
                                            <span>{lang('Maximum Reward')}</span>
                                        </flex>
                                        <FluxInput
                                            field={{
                                                name: 'maximum',
                                                type: InputTypes.Number,
                                                required: true,
                                            }}
                                            onChange={(field, value) => update(field, value)}
                                            data={configuration}
                                            focused={focusedField == 'maximum'} />
                                    </forminput>
                                </row>
                                <row>
                                </row>
                                <row>
                                    <forminput>
                                        <flex className='input-title fit-children'>
                                            <span>{lang('Absolute Reward')}</span>
                                        </flex>
                                        <FluxInput
                                            field={{
                                                name: 'absoluteReward',
                                                type: InputTypes.Number,
                                            }}
                                            onChange={(field, value) => update(field, value)}
                                            data={configuration}
                                            focused={focusedField == 'absoluteReward'} />
                                    </forminput>
                                </row>
                            </flex>
                        </section>
                    </rows>
                </fluxform>
            </flex>
        </FluxCard >
    </flex >;
};