import './_flux-resizable-card.scss';

import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Icon } from '../icon/Icon';

export const FluxResizableCard = ({ children, minLeftWidth }) => {
    const [leftCard, rightCard] = children;
    const [leftWidth, setLeftWidth] = useState(minLeftWidth);
    const [isDragging, setIsDragging] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseMove = (e) => {
        if (!isDragging || !containerRef.current) return;

        const containerRect = containerRef.current.getBoundingClientRect();
        const containerWidth = containerRect.width;

        const newLeftWidth = Math.max(
            minLeftWidth,
            Math.min(e.clientX - containerRect.left, containerWidth)
        );

        setLeftWidth(newLeftWidth);
    };

    if (!Array.isArray(children) || children.length !== 2) {
        return <></>;
    }

    return <flex className='gap-10 overflow-hidden fit-children align-items-start resizable-container' ref={containerRef}>
        <div className='left-card' style={{ width: `${leftWidth}px` }}>
            {leftCard}
        </div>
        <div className='divider' onMouseDown={handleMouseDown} title='Drag to resize'>
            <Icon className='divider-icon' icon='grip-vertical' />
        </div>
        <div className='right-card' style={{ width: `calc(100% - ${leftWidth}px)` }}>
            {rightCard}
        </div>
    </flex>;
};

FluxResizableCard.propTypes = {
    children: PropTypes.node.isRequired,
    minLeftWidth: PropTypes.number.isRequired,
};
