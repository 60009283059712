/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ProviderGameTypes } from '../../../lib/Enums/GameEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { calculateRTP } from '../../../lib/Helpers/RTPHelper';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import { openProfile } from '../../../lib/Helpers/NavigationHelper';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EventTime',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'GameId',
            title: 'Game Id'
        },
        {
            name: 'GameName',
            title: 'Game'
        },
        {
            name: 'GameType',
            title: 'Game Type',
            type: InputTypes.Select,
            values: ProviderGameTypes,
            nullable: true
        },
        {
            name: 'ProviderId',
            title: 'Providers',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'providers/gaming',
                key: 'id',
                title: ['display']
            }
        },
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Operator'
        },
        {
            name: 'PlayerId',
            title: 'Id'
        },
        {
            name: 'Group'
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return openProfile(val,row.PlayerId);
            }
        },
        {
            name: 'Code',
            type: InputTypes.Text
        },
        {
            name: 'RetailName',
            title: 'Retail Name',
            type: InputTypes.Text
        },
        {
            name: 'GameName',
            title: 'Game'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Bet',
            title: 'Bet',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Win',
            title: 'Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Cancel',
            title: 'Cancel',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'RTP',
            title: 'RTP',
            type: InputTypes.Number,
            render: (val, row) => calculateRTP(row)

        },
        {
            name: 'TotalHands',
            title: 'Total Games',
            type: InputTypes.Number,
        }
    ]
};

export const ReportPlayerGroupsCasinoRevenues = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;

    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;

    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.PlayerGroupsGameRevenuesReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
