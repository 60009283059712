/* eslint-disable react/display-name */
import React from 'react';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxPage } from '../../../components/page/FluxPage';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { POSEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';


export const SearchPOSPlayerTransactions = () => {
    const model = {
        fields: [
            {
                name: 'EntryDate',
                title: 'Date',
                type: InputTypes.DateTime,
                between: true,
                value: {
                    start: DateHelpers.yesterday(),
                    end: DateHelpers.tonight()
                }
            }         
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.DateTime
            },
            {
                name: 'Type',
                title: 'Type',
                type: InputTypes.Select,
                nullable: true,
                multiple: true,
                exact: true,
                value: [2,3],
                values: {
                    3: 'Withdraw from Player',
                    2: 'Deposit to Player'
                }
            },
            {
                name: 'Status',
                title: 'Status',
                type: InputTypes.Select,
                nullable: true,
                exact: true,
                value: 1,
                values: {
                    1: 'Confirmed',
                    2: 'Cancelled',
                }
            },
            {
                name: 'Username',
                title: 'Player'
            },
            {
                name: 'Currency',
            },
            {
                name: 'Amount',
                render: (val, row) => {
                    return <Currency amount={val} sign={row.Currency} />;
                }
            },
            {
                name: 'BalanceBefore',
                title: 'Balance Before',
                render: (val, row) => {
                    return <Currency amount={val} sign={row.Currency} />;
                }
            },
            {
                name: 'BalanceAfter',
                title: 'Balance After',
                render: (val, row) => {
                    return <Currency amount={val} sign={row.Currency} />;
                }
            }
        ]
    };



    return <FluxPage action={ActionTypes.POSSearchPlayerTransactions}>
        <FluxSearch title='Search POS Player Transactions'
            model={model}
            resultModel={resultModel}
            endpoint={POSEndpoints.POSSearchPlayerTransactions}/>
        asdfas
    </FluxPage >;
};