/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../card/FluxCard';
import { lang } from '../../../../lib/Localization/language';
import { Button } from '../../../button/Button';
import { Table } from '../../../table/Table';
import { TraderEndpoints } from '../../../../lib/API/Endpoints';
import { API } from '../../../../lib/API/Api';
import { FluxNotice } from '../../../notification/FluxNotice';


export const MarketView = (props) => {
    const { event, withBets } = { ...props };

    const [markets, setMarkets] = useState(null);


    useEffect(() => {
        API.get(withBets ? TraderEndpoints.EventSelectionsWithBets(event.id) : TraderEndpoints.EventSelections(event.id)).then((result) => {
            let marketsDictionary = {};

            result.result.map(x => {
                if (!marketsDictionary[x.marketId]) {
                    marketsDictionary[x.marketId] = {
                        id: x.marketId,
                        name: x.marketName,
                        selections: []
                    };
                }
                marketsDictionary[x.marketId].selections.push(x);
            });

            setMarkets(marketsDictionary);
        });
    }, []);

    const updateSettlement = (tableContext, row, status) => {
        API.post(TraderEndpoints.SendManualSettlement(event.id, row.marketId, row.selectionId, status), null, 'Updating settlement').then(() => {
            let data = tableContext.getData();
            data.find(x => x.selectionId == row.selectionId).settlement = status;
            data.find(x => x.selectionId == row.selectionId).status = 3;
            tableContext.setData([...data]);
        });

    };

    const win = (tableContext, row) => {
        updateSettlement(tableContext, row, 2);
    };

    const lost = (tableContext, row) => {
        updateSettlement(tableContext, row, 1);
    };

    const refund = (tableContext, row) => {
        updateSettlement(tableContext, row, 3);
    };

    const halfLost = (tableContext, row) => {
        updateSettlement(tableContext, row, 4);
    };

    const halfWin = (tableContext, row) => {
        updateSettlement(tableContext, row, 5);
    };

    const open = (tableContext, row) => {
        updateSettlement(tableContext, row, 0);
    };

    const renderMarket = (m) => {
        let marketData = markets[m];
        return <FluxCard toggleBody title={<React.Fragment>
            <span>[{marketData.id}]</span>
            <span>{marketData.name}</span>
        </React.Fragment>} buttons={<flex className='align-right gap-10'>
            <Button className='warning' title='Bet Stop' />
            <Button className='warning' title='Disable auto settlements' />
        </flex>}>
            <Table
                className={'fx-borders fx-shadow'}
                model={{
                    fields: [
                        {
                            name: 'manualSuspend',
                            title: ' ',
                            render: (val, row) => {
                                return <flex onClick={() => {
                                    console.log(row);
                                }}>
                                    <Button>
                                        <i className='fa-regular fa-pause' />
                                    </Button>
                                </flex>;
                            }
                        },
                        {
                            name: 'selectionId',
                            title: 'SelectionId'
                        },
                        {
                            name: 'selectionName',
                            title: 'Name'
                        },
                        {
                            name: 'baseLine',
                            title: 'Base Line'
                        },
                        {
                            name: 'line',
                            title: 'Line'
                        },
                        {
                            name: 'price',
                            title: 'Price'
                        },
                        {
                            name: 'totalTickets',
                            title: 'Total Tickets',
                            hidden: !withBets
                        },
                        {
                            name: 'status',
                            title: 'Status',
                            render: (val) => {
                                switch (val) {
                                    case 1:
                                        return <span>{lang('Open')}</span>;
                                    case 2:
                                        return <span>{lang('Suspended')}</span>;
                                    case 3:
                                        return <span>{lang('Settled')}</span>;
                                    default:
                                        return val;
                                }
                            }
                        },
                        {
                            name: 'officialSettlement',
                            title: 'Official',
                            render: (val) => {
                                switch (val) {
                                    case -1:
                                        return <span>{lang('Cancelled')}</span>;
                                    case 0:
                                        return <span>{lang('Open')}</span>;
                                    case 1:
                                        return <span>{lang('Loser')}</span>;
                                    case 2:
                                        return <span>{lang('Winner')}</span>;
                                    case 3:
                                        return <span>{lang('Refund')}</span>;
                                    case 4:
                                        return <span>{lang('Half Lost')}</span>;
                                    case 5:
                                        return <span>{lang('Half Win')}</span>;
                                    default:
                                        return val;
                                }
                            }
                        },
                        {
                            name: 'settlement',
                            title: 'Settlement',
                            render: (val) => {
                                switch (val) {
                                    case -1:
                                        return <span>{lang('Cancelled')}</span>;
                                    case 0:
                                        return <span>{lang('Open')}</span>;
                                    case 1:
                                        return <span>{lang('Loser')}</span>;
                                    case 2:
                                        return <span>{lang('Winner')}</span>;
                                    case 3:
                                        return <span>{lang('Refund')}</span>;
                                    case 4:
                                        return <span>{lang('Half Lost')}</span>;
                                    case 5:
                                        return <span>{lang('Half Win')}</span>;
                                    default:
                                        return val;
                                }
                            }
                        }
                    ]
                }} data={marketData.selections}
                buttons={(row, val, tableContext) => {

                    return <flex className='gap-10'>
                        <flex className='gap-5'>
                            <Button title='WIN' className='success' onClick={() => win(tableContext, row)} disabled={row.settlement == 2} />
                            <Button title='LOST' className='warning' onClick={() => lost(tableContext, row)} disabled={row.settlement == 1} />
                            <Button title='REFUND' className='' onClick={() => refund(tableContext, row)} disabled={row.settlement == 3} />
                            <Button title='HALF LOST' className='success' onClick={() => halfLost(tableContext, row)} disabled={row.settlement == 4} />
                            <Button title='HALF WIN' className='success' onClick={() => halfWin(tableContext, row)} disabled={row.settlement == 5} />
                        </flex>
                        <Button title='OPEN' className='success' onClick={() => open(tableContext, row)} disabled={row.settlement == 0} />
                    </flex>;
                }}
                onRowClick={(row, element, index) => {
                    console.log(index);
                }}
            />
        </FluxCard>;
    };

    if (!markets || Object.keys(markets).length == 0) return <flex>
        <FluxNotice type={'information'} title='There are no bets' />
    </flex>;

    return <flex className='vertical gap-10'>
        {Object.keys(markets).map(x => {
            return renderMarket(x);
        })}
    </flex>;
};