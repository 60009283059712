/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../components/button/Button';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { MultipleSelectInput } from '../../../../../components/form/Components/MultipleSelectInput';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { Table } from '../../../../../components/table/Table';
import { lang } from '../../../../../lib/Localization/language';
import { fetchGroups, showAddNewGroupModal } from '../../../../Players/PlayerGroups/PlayerGroups';
import { ToggleButton } from '../../../../../components/toggleButton/ToggleButton';


const sortGroups = (a, b) => a.Name > b.Name ? 1 : -1;
const field = {
    values: {
        'All': 1,
        'Online': 2,
        'Retail': 3
    },
    value: 1
};

const tableModel = {
    fields: [
        { name: 'Id', hidden: true },
        { name: 'Name', title: 'Group Name' },
        { name: 'Description', title: 'Group Description' },
        {
            name: 'Retail',
            title: 'Online/Retail',
            type: InputTypes.Bool,
            render: (val) => {
                return <flex>
                    <span>{lang(val ? 'Retail' : 'Online')}</span>
                </flex>;
            }
        },
        {
            name: 'Disabled',
            type: InputTypes.Bool,
            render: (val, row, field, context) => {
                return <ToggleButton key={`${row.id}_${field.name}`} value={row.Disabled} onChange={(value) => {
                    if (row.Disabled === value) return;
                    context.toggleDisabled(row, value);
                }} />;
            }
        },
        {
            name: 'Percentage',
            render: (val, row, field, context) => <flex key={row.Id} className="gap-5">
                <input type='number'
                    name={field.name}
                    min={0}
                    step={0.01}
                    defaultValue={val}
                    onChange={(e) => context.updatePercentage(e, row)} />
                <span>%</span>
            </flex>
        },
        {
            name: 'AbsoluteReward',
            title: 'Absolute Reward',
            render: (val, row, field, context) => <flex key={row.Id} className="gap-5">
                <input type='number'
                    name={field.name}
                    min={0}
                    step={0.01}
                    defaultValue={val}
                    onChange={(e) => context.updateAbsoluteReward(e, row)} />
            </flex>
        }
    ]
};


export const CashbackBonusPlayerGroupSettings = (props) => {
    const { promotion, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(promotion.Configuration ?? {});

    const [groups, setGroups] = useState([]);
    const [showFiltersMenu, toggleFilters] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(1);

    useEffect(() => {
        fetchPlayerGroups();
    }, []);

    const getData = () => {
        var res = groups;

        if (searchFilter) {
            var text = searchFilter.toLowerCase();
            res = groups.filter(x => x.Name.toLowerCase().includes(text));
            if (onlineFilter == 2) {
                res = res.filter(x => x.Retail == false);
            } else if (onlineFilter == 3) {
                res = res.filter(x => x.Retail == true);
            }
        }
        else {
            if (onlineFilter == 2) {
                res = res.filter(x => x.Retail == false);
            } else if (onlineFilter == 3) {
                res = res.filter(x => x.Retail == true);
            }
        }
        return res;
    };


    const roundToHundredth = (value) => {
        if (value >= 0.001 && value <= 0.009) {
            return 0.01;
        }
        return Math.round(value * 100) / 100;
    };

    const getGroup = (id) => {
        var copy = { ...promotion };
        copy.Configuration = configuration;
        if (!copy.Configuration) {
            copy.Configuration = {};
        }
        if (!copy.Configuration['groups']) {
            copy.Configuration['groups'] = []
        }
        var d = copy.Configuration['groups'].find(x => x.Id == id)
        return { d, copy };
    }

    const context = {
        updatePercentage: (e, row) => {
            var value = e.target.value;
            if (field.type == InputTypes.Number) {
                value = parseFloat(value);
            }
            if (e.target.type == 'number') {
                value = roundToHundredth(parseFloat(value));
                e.target.value = value;
            }
            const { d, copy } = getGroup(row.Id);
            if (d) {
                if (d.Percentage == value) return;
                d.Percentage = value;
            } else {
                copy.Configuration['groups'].push({ Id: row.Id, Percentage: value });
            }
            onChange(copy);
            setConfiguration(copy.Configuration);

        },
        updateAbsoluteReward: (e, row) => {
            var value = e.target.value;
            if (field.type == InputTypes.Number) {
                value = parseFloat(value);
            }
            if (e.target.type == 'number') {
                value = roundToHundredth(parseFloat(value));
                e.target.value = value;
            }
            const { d, copy } = getGroup(row.Id);
            if (d) {
                if (d.AbsoluteReward == value) return;
                d.AbsoluteReward = value;
            } else {
                copy.Configuration['groups'].push({ Id: row.Id, AbsoluteReward: value });
            }
            onChange(copy);
            setConfiguration(copy.Configuration);

        },
        toggleDisabled: (row, value) => {
            const { d, copy } = getGroup(row.Id);
            if (d) {
                if (d.Disabled == value) return;
                d.Disabled = value;
            } else {
                copy.Configuration['groups'].push({ Id: row.Id, Disabled: value });
            }
            onChange(copy);
            setConfiguration(copy.Configuration);
        }
    };

    const fetchPlayerGroups = () => {
        fetchGroups().then((result) => {
            result.result.map(x => {
                const group = (configuration?.groups ?? []).find(y => y.Id == x.Id);
                if (group) {
                    x['AbsoluteReward'] = group.AbsoluteReward;
                    x['Percentage'] = group.Percentage;
                    x['Disabled'] = group.Disabled;
                }
                return x;
            });
            setGroups(result.result.sort((a, b) => sortGroups(a, b)));
        });
    };

    return <FluxCard title='Groups'
        buttons={<flex className='gap-5 align-right'>
            <Button title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} onClick={() => toggleFilters(!showFiltersMenu)} />
            <Button className={'marginRight'} title='Add Group' onClick={() => showAddNewGroupModal(() => fetchPlayerGroups())} />
        </flex>}>
        {
            showFiltersMenu &&
            <flex className='padding gap-10 border'>
                <flex className='padding border-bottom gap-10 fx-shadow' >
                    <input type='text' className='w-100' placeholder='Search' defaultValue={null}
                        onChange={(e) => setSearchFilter(e.target.value)} />
                </flex>
                <flex className={'padding border-bottom gap-10 fx-shadow'}>
                    <span className='bold'>{lang('Type')}</span>
                    <MultipleSelectInput
                        data={1}
                        field={field}
                        onChange={(a, b) => setOnlineFilter(b)} />
                </flex>
                <hr></hr>
            </flex>
        }
        <Table
            className={'fx-borders fx-shadow limits-table'}
            key={'Id'}
            model={tableModel}
            context={context}
            data={getData()} />
    </FluxCard>;
};