/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues, footerValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import './_report-casino-operational.scss';

const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Date',
            title: 'Date',
            type: InputTypes.Date,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        // {
        //     name: 'RetailCode',
        //     title: 'Retail Code'
        // },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        // {
        //     name: 'ConvertCurrency',
        //     title: 'Convert Currency',
        //     type: InputTypes.Bool
        // },
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

const defaultCurrencyFieldWithFooter = (name, title, customHeader) => {
    if (customHeader) {
        return {
            name: name,
            title: title,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true),
            renderHeaderWithSorting: () => customHeader(),
        };
    }
    return {
        name: name,
        title: title,
        render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        renderFooter: (val) => footerCurrencyValues(val),
        exportFooter: (val) => footerCurrencyValues(val, true)
    };
};

const resultModel = {
    fields: [
        {
            name: 'Date',
            title: 'Date',
            type: InputTypes.Date
        },
        {
            name: 'NewPlayerCount',
            title: 'New Player Count',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>New</span>
                    <span className='center'>Player</span>
                    <span className='center'>Count</span>
                </div>;
            },
            renderFooter: (val) => val,
            exportFooter: (val) => val
        },
        {
            name: 'FirstDepositCount',
            title: 'First Deposit Count',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>First</span>
                    <span className='center'>Deposit</span>
                    <span className='center'>Count</span>
                </div>;
            },
            renderFooter: (val) => val,
            exportFooter: (val) => val
        },
        {
            name: 'ActiveUniquePlayerCount',
            title: 'Active Unique Player Count',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Active Unique</span>
                    <span className='center'>Player Count</span>
                </div>;
            },
            renderFooter: (val) => val,
            exportFooter: (val) => val
        },
        {
            name: 'Currency',
            title: 'Currency',
            type: InputTypes.Text,
        },
        // {
        //     name: '|',
        //     render: () => '|',

        // },
        {
            name: 'LiveCasinoGames',
            title: 'Live Casino Games',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live Casino</span>
                    <span className='center'>Games</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        {
            name: 'LiveCasinoFreeSpinGames',
            title: 'Live Casino Free Spin Games',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live Casino</span>
                    <span className='center'>Free Spin</span>
                    <span className='center'>Games</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        {
            name: 'CasinoGames',
            title: 'Casino Games',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Games</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        {
            name: 'CasinoFreeSpinGames',
            title: 'Casino Free Spin Games',
            type: InputTypes.Number,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Free Spin</span>
                    <span className='center'>Games</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        // {
        //     name: '|',
        //     render: () => '|',

        // },
        //#region Live Casino
        defaultCurrencyFieldWithFooter('LiveCasinoTurnover', 'Live Casino ', () => {
            return <div className='flex vertical'>
                <span className='center'>Live Casino</span>
                <span className='center'>Cash Turnover</span>
            </div >;
        }),
        defaultCurrencyFieldWithFooter('LiveCasinoWin', 'Live Casino ', () => {
            return <div className='flex vertical'>
                <span className='center'>Live Casino</span>
                <span className='center'>Cash Win</span>
            </div >;
        }),
        defaultCurrencyFieldWithFooter('LiveCasinoProfit', 'Live Casino ', () => {
            return <div className='flex vertical'>
                <span className='center'>Live Casino</span>
                <span className='center'>Cash Profit</span>
            </div >;
        }),
        {
            name: 'LiveCasinoMargin',
            title: 'Live Casino Margin',
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live Casino</span>
                    <span className='center'>Margin</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false),
            exportFooter: (val) => footerValues(val, true)
        },
        // {
        //     name: '|',
        //     render: () => '|',

        // },
        //#endregion

        //#region Casino
        defaultCurrencyFieldWithFooter('CasinoTurnover', 'Casino ', () => {
            return <div className='flex vertical'>
                <span className='center'>Casino</span>
                <span className='center'>Cash Turnover</span>
            </div >;
        }),
        defaultCurrencyFieldWithFooter('CasinoWin', 'Casino ', () => {
            return <div className='flex vertical'>
                <span className='center'>Casino</span>
                <span className='center'>Cash Win</span>
            </div >;
        }),
        defaultCurrencyFieldWithFooter('CasinoProfit', 'Casino ', () => {
            return <div className='flex vertical'>
                <span className='center'>Casino</span>
                <span className='center'>Cash Profit</span>
            </div >;
        }),
        {
            name: 'CasinoMargin',
            title: 'Casino Margin',
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Margin</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false),
            exportFooter: (val) => footerValues(val, true)
        },
        //#endregion
        
        
        //#region All
        // {
        //     name: '|',
        //     render: () => '|',

        // },
        defaultCurrencyFieldWithFooter('Turnover', 'Cash Turnover'),
        defaultCurrencyFieldWithFooter('Win', 'Cash Win'),
        defaultCurrencyFieldWithFooter('Profit', 'Cash Profit'),
        {
            name: 'Margin',
            title: 'Margin',
            renderFooter: (val) => footerValues(val, false),
            exportFooter: (val) => footerValues(val, true)
        },
        //#endregion
        defaultCurrencyFieldWithFooter('GGR', 'GGR'),
        defaultCurrencyFieldWithFooter('NGR', 'NGR'),
        // {
        //     name: '|',
        //     render: () => '|',

        // },
        {
            name: 'LiveCasinoCancel',
            title: 'Live Casino Cancel Hands',
            type: InputTypes.Number,
            hidden:true,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live Casino</span>
                    <span className='center'>Cancel Hands</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        {
            name: 'LiveCasinoCanceled',
            title: 'Live Casino Canceled',
            type: InputTypes.Number,
            hidden:true,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Live Casino</span>
                    <span className='center'>Canceled</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        {
            name: 'CasinoCancel',
            title: 'Casino Cancel Hands',
            type: InputTypes.Number,
            hidden:true,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Cancel Hands</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
        {
            name: 'CasinoCanceled',
            title: 'Casino Canceled',
            type: InputTypes.Number,
            hidden:true,
            renderHeaderWithSorting: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Canceled</span>
                </div>;
            },
            renderFooter: (val) => footerValues(val, false, 0),
            exportFooter: (val) => footerValues(val, true, 0)
        },
    ]
};


export const ReportCasinoOperational = () => {
    const user = useSelector((state) => state.user.loginInformation);

    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;

    return <FluxSearch
        tableClassName={'report-casino-operational'}
        model={searchModel}
        endpoint={ReportEndpoints.ReportCasinoOperational}
        resultModel={resultModel}
        exportAllData={true}
        onBeforeSearch={(payload) => {
            var copy = { ...payload };
            copy.name = 'report-casino-operational';
            copy.fields.push({
                name: 'TimeZoneOffset',
                title: 'TimeZoneOffset',
                readonly: true,
                type: 'hidden',
                hidden: true,
                value: window.user.timeZoneConverted
            });
            return copy;
        }}
    />;
};
