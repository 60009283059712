import './_trader-tools-page.scss';

import React, { useEffect, useState } from 'react';
import { FluxTab } from '../../tab/FluxTab';
import { FluxCard } from '../../card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import ReactSelect from 'react-select';
import { Button } from '../../button/Button';
import { DateTimeInput } from '../../form/Components/DateTimeInput';
import { InputTypes } from '../../form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { Table } from '../../table/Table';
import { EventStatus } from '../../../lib/Enums/BettingEnums';
import { TraderEndpoints, SportEndpoints } from '../../../lib/API/Endpoints';
import { appHistory } from '../../../..';
import { EventView } from './Components/EventView';
import { API } from '../../../lib/API/Api';


const resultModel = {
    fields: [
        {
            name: 'date',
            title: 'Event Date',
            type: InputTypes.DateTime
        },
        {
            name: 'id',
            title: 'Event Id'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'region',
            title: 'Region'
        },
        {
            name: 'league',
            title: 'League'
        },
        {
            name: 'eventName',
            title: 'Event',
            render: (val, row) => {
                let data = row.name.split(' vs ');
                let home = data[0];
                let away = data[1];
                return <flex className='vertical'>
                    <span>{home}</span>
                    <span>{away}</span>
                </flex>;
            }
        },
        {
            name: 'status',
            title: 'Status',
            type: InputTypes.Select,
            values: EventStatus
        },
        {
            name: 'totalTickets',
            title: 'Total Tickets'
        },
        {
            name: 'subscribed',
            title: 'Subscribed',
            type: InputTypes.Bool
        }
    ]
};



export const TraderToolsPage = () => {
    const [sports, setSports] = useState(null);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [sport, selectSport] = useState(null);
    const [region, selectRegion] = useState(null);
    const [league, selectLeague] = useState(null);

    const [data, setData] = useState({ startDate: DateHelpers.startOfWeek(), endDate: DateHelpers.tonight(), eventsWithTickets: true });
    const [events, setEvents] = useState(null);
    const [event, selectEvent] = useState(undefined);

    let tabButtons = [
        {
            title: 'Event Browser'
        }
        /*
        ,

        {
            title: 'Upcoming Events'
        },
        {
            title: 'Live Events'
        },
        {
            title: 'Top Events'
        },
        {
            title: 'Top Leagues'
        }
        */
    ];

    useEffect(() => {
        API.post(SportEndpoints.Categories, null, 'Loading Categories').then(result => {
            setSports(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            const urlParams = new URLSearchParams(window.location.search);
            const e = urlParams.get('e'); // retail login
            if (!isNaN(parseFloat(e))) {
                selectEvent({ id: e, empty: true });
            }

        });
    }, []);

    useEffect(() => {
        if (event === undefined) return;
        if (!event) {
            appHistory.push(`${window.location.pathname}`);
            return;
        }

        appHistory.push(`${window.location.pathname}?&e=${event.id}`);

    }, [event]);

    useEffect(() => {
        if (!sports) return;
        if (!sport) {
            selectSport(sports.find(x => x.id == 6046));
        }
    }, [sports]);

    useEffect(() => {
        if (!regions) return;
    }, [regions]);

    useEffect(() => {
        if (!sport) return;
        updateForm('sportId', sport.id);
        API.post(`${SportEndpoints.Regions}/${sport.id}`, null, 'Loading Regions').then((result) => {
            setRegions(result.result);
        });
    }, [sport]);

    useEffect(() => {
        if (!region) return;
        updateForm('regionId', region.id);
        API.post(`${SportEndpoints.Leagues}/${sport.id}/${region.id}`, null, 'Loading Leagues').then((result) => {
            setLeagues(result.result);
        });
    }, [region]);

    useEffect(() => {
        if (!league) return;
        updateForm('leagueId', league.id);
    }, [league]);



    const renderSports = () => {
        let selectedValue = null;
        let options = sports?.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });

        selectedValue = sport ? options.find(x => x.value == sport.id) : null;

        return <flex className='vertical gap-5 input-title'>
            <span>{lang('Sports')}</span>
            <ReactSelect
                className={`react-select ${sports ? '' : 'disabled'}`}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                menuPortalTarget={document.body}
                defaultValue={selectedValue}
                options={options}
                key={selectedValue}
                onChange={(e) => {
                    if (!e) {
                        selectSport(null);
                        return;
                    }
                    setRegions(null);
                    selectSport(sports.find(x => x.id == e.value));
                }}
                name={'Sports'}

                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={true}
                isSearchable={true}
            />
        </flex>;
    };

    const renderRegions = () => {
        let selectedValue = null;
        let options = regions?.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });

        return <flex className='vertical gap-5 input-title'>
            <span>{lang('Regions')}</span>
            <ReactSelect
                className={`react-select ${regions ? '' : 'disabled'}`}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                menuPortalTarget={document.body}
                defaultValue={selectedValue}
                options={options}
                onChange={(e) => {
                    if (!e) {
                        selectRegion(null);
                        return;
                    }
                    selectRegion(regions.find(x => x.id == e.value));
                }}
                name={'Regions'}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={true}
                isSearchable={true}
            />
        </flex>;
    };

    const renderLeagues = () => {
        let selectedValue = null;
        let options = leagues?.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });

        return <flex className='vertical gap-5 input-title'>
            <span>{lang('Leagues')}</span>
            <ReactSelect
                className={`react-select ${leagues ? '' : 'disabled'}`}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                menuPortalTarget={document.body}
                defaultValue={selectedValue}
                options={options}
                onChange={(e) => {
                    if (!e) {
                        selectLeague(null);
                        return;
                    }
                    selectLeague(leagues.find(x => x.id == e.value));
                }}
                name={'Leagues'}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={true}
                isSearchable={true}
            />
        </flex>;
    };

    const getDateFromElement = (element) => {
        if (!element) return;
        let day = element.getElementsByClassName('input-day')[0].value;
        let month = element.getElementsByClassName('input-month')[0].value;
        let year = element.getElementsByClassName('input-year')[0].value;

        let hours = element.getElementsByClassName('input-hours')[0].value;
        let minutes = element.getElementsByClassName('input-minutes')[0].value;
        return `${year}-${month}-${day} ${hours}:${minutes}Z`;
    };

    const searchEvents = () => {
        if (!data.sportId) return;

        let copy = { ...data };
        copy.startDate = getDateFromElement(document.getElementsByClassName('startDate')[0]);
        copy.endDate = getDateFromElement(document.getElementsByClassName('endDate')[0]);

        API.post(TraderEndpoints.BrowseEvents, copy, 'Please wait loading events', 'Unable to fetch events').then((result) => {
            setEvents(result.result);
        });
    };

    const updateForm = (name, value) => {
        let copy = { ...data };
        copy[name] = value;
        copy.startDate = getDateFromElement(document.getElementsByClassName('startDate')[0]);
        copy.endDate = getDateFromElement(document.getElementsByClassName('endDate')[0]);


        setData(copy);
    };

    if (event) {
        return <flex className='trader-tools-page'><EventView event={event} onClose={() => {
            selectEvent(null);
        }} />
        </flex>;
    }
    return <flex className='trader-tools-page vertical gap-20 overflow-hidden'>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
            <flex className='vertical gap-10 overflow-hidden'>
                <FluxCard title='Search Events'>
                    <flex className='vertical gap-10'>
                        <flex className='gap-10'>
                            {renderSports()}
                            {renderRegions()}
                            {renderLeagues()}
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Status')}</span>
                                <ReactSelect
                                    className={'react-select'}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    menuPortalTarget={document.body}
                                    defaultValue={[{
                                        label: 'Prematch',
                                        value: 1
                                    },
                                    {
                                        label: 'Live',
                                        value: 2
                                    }]}
                                    options={Object.keys(EventStatus).map(x => {
                                        return {
                                            label: EventStatus[x],
                                            value: x
                                        };
                                    })}
                                    onChange={(e) => {
                                        if (!e) {
                                            updateForm('status', null);
                                            return;
                                        }

                                        updateForm('status', e.map(x => x.value).join(','));
                                    }}
                                    isMulti={true}
                                    name={'Status'}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isSearchable={true}
                                />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Events')}</span>
                                <ReactSelect
                                    className={'react-select'}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    menuPortalTarget={document.body}
                                    defaultValue={{
                                        label: 'With tickets only',
                                        value: true
                                    }}
                                    options={[
                                        {
                                            label: 'All',
                                            value: false
                                        },
                                        {
                                            label: 'With tickets only',
                                            value: true
                                        }
                                    ]}
                                    onChange={(e) => {
                                        updateForm('eventsWithTickets', e.value);
                                    }}
                                    name={'EventsWithTickets'}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Start Date')}</span>
                                <DateTimeInput field={{ name: 'startDate', value: data.startDate, type: InputTypes.DateTime }} data={data} onChange={() => {
                                }} />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('End Date')}</span>
                                <DateTimeInput field={{ name: 'endDate', value: data.endDate, type: InputTypes.DateTime }} data={data} onChange={() => {
                                }} />
                            </flex>
                        </flex>
                        <hr />
                        <flex className='gap-10'>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Search Event By Name')}</span>
                                <input onChange={(e) => updateForm('name', e.target.value)} />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Search Event By Id')}</span>
                                <input />
                            </flex>
                        </flex>
                        <flex>
                            <Button title='Search' className={'success'} onClick={() => {
                                searchEvents();
                            }} />
                        </flex>
                    </flex>
                </FluxCard>
                <FluxCard className='overflow-hidden trader-tool-events'>
                    <div className='events-holder'>
                        <Table
                            className={'fx-borders fx-shadow'}
                            model={resultModel} data={events ?? []}
                            buttons={(row) => {
                                return <flex className='gap-5'>
                                    <Button title='View Event' className={'success'} onClick={() => {
                                        selectEvent(row);
                                    }} />
                                    {row.subscribed && <Button title='Unsubscribe' className={'warning'} />}
                                    {!row.subscribed && <Button title='Subscribe' className={'success'} />}
                                </flex>;
                            }}
                            onRowClick={(row, element, index) => {
                                console.log(index);
                            }}
                        />
                    </div>
                </FluxCard>
            </flex>
        </FluxTab>
    </flex>;
};