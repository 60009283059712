import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { confirmPendingTicket, rejectPendingTicket } from '../../../actions/PendingTicketActions';
import { InputTypes } from '../../../components/form/InputTypes';
import { Icon } from '../../../components/icon/Icon';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DataEndpoints, SportPendingTickets, TicketEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { SportsBetTypes, SportsTicketStatus, SportsTicketTypes } from '../../../lib/Enums/BettingEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { lang } from '../../../lib/Localization/language';
import { ModelInvestigatedTickets, ModelSportTicketResult } from '../../../lib/Models/TicketModels';
import { API } from '../../../lib/API/Api';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'ExternalTicketId',
            title: 'Ticket No',
            type: InputTypes.Number
        },
        {
            name: 'SportId',
            title: 'Sport',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'sportCategories',
                key: 'id',
                title: ['name']
            }
        },
        {
            name: 'EventId',
            title: 'Event Id'
        },
        {
            name: 'LeagueId',
            title: 'League Id'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Username',
            exact: false
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            values: SportsBetTypes,
            nullable: true
        },
        {
            name: 'TicketType',
            title: 'Ticket Type',
            type: InputTypes.Select,
            values: SportsTicketTypes,
            nullable: true
        },
        {
            name: 'Status',
            title: 'Ticket Status',
            type: InputTypes.Select,
            values: SportsTicketStatus,
            nullable: true
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'promotion',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField
    ]
};




export const SearchPlayerBetHistory = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;
    const [research, setResearch] = useState(0);

    const defaultCurrency = useSelector((state) => state.user.loginInformation.features.currency);
    const [selectedCurrency, selectCurrency] = useState(defaultCurrency);

    const [currencies, setCurrencies] = useState([]);
    const [endpoint, setEndpoint] = useState(null);

    useEffect(() => {
        API.post(DataEndpoints.EnabledCurrencies).then((result) => {
            var res = [{ CurrencyCode: '-', Name: 'All' }, ...result.result]
            setCurrencies(res);
        });

        setEndpoint(props.resolved ? TicketEndpoints.Resolved : TicketEndpoints.Search);
    }, []);

    const title = () => {
        if (props.pending) {
            return 'Investigated tickets';
        }
        if (props.eventId) {
            return <React.Fragment><span>{lang('Event')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.eventId}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Tickets')}</span>
                <select className='align-right' onChange={(e) => {
                    selectCurrency(e.target.value);
                    setResearch(research => research + 1);
                }}>
                    {currencies.map(x => {
                        return <option key={x} value={x.CurrencyCode}
                            selected={x.CurrencyCode == defaultCurrency}>{lang(x.Name)}</option>;
                    })}
                </select>
            </React.Fragment>;
        }

        if (props.retail) {
            return <React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Bet History')}</span></React.Fragment>;
        }
        if (props.affiliate) {
            return <React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search in Player Bet History')}</span></React.Fragment>;
        }
        return 'Search in Player Bet History';
    };

    const onActionClicked = (action, ticket, selection) => {
        var data = selection.tableContext.getData();
        var ticketId = ticket.Id;
        var operatorId = ticket.OperatorId;

        switch (action.type) {
            case ActionTypes.ConfirmTransaction:
                confirmPendingTicket(SportPendingTickets.confirm, operatorId, ticketId, ticket.Win, () => {
                    data = data.filter(x => x.Id != ticketId);
                    selection.tableContext.setData(data);
                });
                break;
            case ActionTypes.RejectTransaction:
                rejectPendingTicket(SportPendingTickets.reject, operatorId, ticketId, ticket.Win, () => {
                    data = data.filter(x => x.ExternalTicketId != ticketId);
                    selection.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };


    return <FluxSearch
        key={props.eventId}
        {...props}
        title={title()}
        model={model}
        resultModel={props.pending ? ModelInvestigatedTickets : ModelSportTicketResult}
        actions={props.pending ? ActionTypes.SportPendingTickets : null}
        endpoint={endpoint}
        searchOnStart
        research={research}
        onActionClicked={onActionClicked.bind(this)}
        onPrepareModel={(model) => {
            var copy = { ...model };

            copy.name = 'player-bet-history-operator';

            if (props.tournament) {
                copy.name = 'player-bet-history-operator-tournament';
                copy.fields.push({
                    name: 'TournamentId',
                    title: 'TournamentId',
                    readonly: true,
                    type: 'hidden',
                    value: props.tournament.Id
                });
                return copy;
            }

            if (props.pending) {
                var statusField = copy.fields.find(x => x.name == 'Status');
                statusField.value = 1;

                copy.name = 'player-bet-history-operator-postponed';
                copy.fields.push({
                    name: 'PostponePayment',
                    title: 'Investigated',
                    readonly: true,
                    type: 'hidden',
                    value: true
                });
                return copy;
            }

            if (props.eventId) {
                copy.name = `player-bet-history-operator-${props.eventId}`;
                copy.fields = [{
                    name: 'EventId',
                    value: props.eventId,
                    readonly: true
                }];
                if (selectedCurrency && selectedCurrency != '-') {
                    copy.name = `player-bet-history-operator-${props.eventId}-${selectedCurrency}`;
                    copy.fields.push(
                        {
                            name: 'Currency',
                            value: selectedCurrency,
                            readonly: true
                        })
                }
                return copy;
            }

            if (user.UserPrivileges.AvailableOperators.length == 1) {
                copy.fields.find(x => x.name == 'OperatorId').hidden = true;
            }
            
            if (props.retail) {
                copy.name = 'player-bet-history-of-retail';
                copy.fields.push({ name: 'RetailId', value: props.retail.Id, exact: true, and: true, hidden: true });
                return copy;
            }

            if (props.affiliate) {
                copy.name = 'player-bet-history-of-affiliate';
                copy.fields.push({ name: 'AffiliateId', value: props.affiliate.Id, exact: true, and: true, hidden: true });
            }

            if (props.resolved) {
                copy.fields = copy.fields.filter(x => x.name != 'Status');
            }

            if (props.cashout) {
                copy.fields = copy.fields.filter(x => x.name != 'Status');
                copy.fields.push({
                    name: 'Status',
                    value: 3,
                    readonly: true,
                    hidden: true
                });
            }


            if (!props.playerId) return copy;
            var playerField = copy.fields.find(x => x.name == 'PlayerId');
            playerField.hidden = true;
            playerField.value = props.playerId;
            copy.fields.find(x => x.name == 'Username').hidden = true;
            copy.fields.find(x => x.name == 'Group').hidden = true;

            copy.name = 'player-bet-history';

            return copy;
        }}
        onPrepareResultModel={(copy) => {
            if (singleOperator) {
                copy.fields.find(x => x.name == 'Operator').hidden = true;
            }

            if (props.affiliate || props.retail) {
                copy.name = 'search-player-bet-history-opeartor-' + (props.retail ?? props.affiliate);
                copy.fields.find(x => x.name == 'RetailName').hidden = true;
                copy.fields.find(x => x.name == 'Group').hidden = true;
                copy.fields.find(x => x.name == 'Operator').hidden = true;
            }

            if (props.tournament) {
                copy.name = 'search-player-bet-history-tournament-' + props.tournament.Id;
                copy.fields.push({
                    name: 'TournamentTicketPoint',
                    title: 'Ticket Point'
                });
            }

            if (!props.playerId) return copy;
            copy.name = 'search-player-bet-history-opeartor-' + props.playerId;
            copy.fields.find(x => x.name == 'PlayerId').hidden = true;
            copy.fields.find(x => x.name == 'Username').hidden = true;
            copy.fields.find(x => x.name == 'RetailName').hidden = true;
            copy.fields.find(x => x.name == 'Group').hidden = true;
            copy.fields.find(x => x.name == 'Operator').hidden = true;
            return copy;
        }}
    />;
};


SearchPlayerBetHistory.propTypes = {
    playerId: PropTypes.number,
    eventId: PropTypes.any,
    cashout: PropTypes.bool,
    resolved: PropTypes.bool,
    affiliate: PropTypes.object,
    retail: PropTypes.object,
    tournament: PropTypes.object,
    pending: PropTypes.bool
};