/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { FluxTab } from '../../../components/tab/FluxTab';
import { DataEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { openProfile } from '../../../lib/Helpers/NavigationHelper';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

const searchModelByProviders = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'RegisterDate',
            title: 'Register Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'PlayerId',
            type: InputTypes.Number
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: DataEndpoints.PaymentProviders,
                key: 'id',
                title: ['display'],
                sort: (a, b) => a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1,
            }
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [0, 1],
            values: {
                0: 'Deposit',
                1: 'Withdraw'
            }
        },
        CurrencyFilterField
    ]
};

const resultModelByProviders = {
    fields: [
        {
            name: 'PlayerId'
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            render: (val, row) => {
                return openProfile(val,row.PlayerId);
            }
        },
        {
            name: 'Provider'
        }
    ]
};


const searchModelAtAllProviders = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'RegisterDate',
            title: 'Register Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'PlayerId',
            type: InputTypes.Number
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        CurrencyFilterField
    ]
};

const resultModelAtAllProviders = {
    fields: [
        {
            name: 'PlayerId'
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            render: (val, row) => {
                return openProfile(val,row.PlayerId);
            }
        },
        {
            name: 'TotalDeposit',
            title: 'Total Deposit',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'TotalWithdraw',
            title: 'Total Withdraw',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
    ]
};

export const ReportPlayersWithNoExistTransactions = () => {
    const tabButtons = [
        {
            title: 'At All Providers'
        },
        {
            title: 'By Providers'
        }
    ];

    return <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
        <FluxSearch
            key={'atAllProviders'}
            model={searchModelAtAllProviders}
            endpoint={ReportEndpoints.TransactionsPlayerWithNoExistAtAllReport}
            resultModel={resultModelAtAllProviders}
            onPrepareResultModel={(model) => {
                var copy = { ...model };

                return copy;
            }}
        />
        <FluxSearch
            key={'byProviders'}
            model={searchModelByProviders}
            endpoint={ReportEndpoints.TransactionsPlayerWithNoExistByProviderReport}
            resultModel={resultModelByProviders}
            onPrepareResultModel={(model) => {
                var copy = { ...model };

                return copy;
            }}
        />
    </FluxTab>;
};
