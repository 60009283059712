/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
import React from 'react';
import { Currency } from '../../components/currency/Currency';
import { InputTypes } from '../../components/form/InputTypes';
import { DateHelpers } from '../Helpers/DateHelpers';
import { footerCurrencyValues } from '../Helpers/Reports/Footer';
import { openProfile } from '../Helpers/NavigationHelper';

export const ModelSearchPlayer = {
    name: 'search-players',
    fields: [
        {
            name: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'IdNumber',
            title: 'Id / Passport',
            type: InputTypes.Text,
            exact: true
        },
        {
            name: 'Email',
            title: 'Email',
            type: InputTypes.Text,
            hidden: true,
            exact: true
        },
        {
            name: 'Phone',
            title: 'Phone',
            type: InputTypes.Text
        },
        {
            name: 'Tag',
            title: 'Tags',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last Login Ip',
            type: InputTypes.Text
        },
        {
            name: 'Enabled',
            title: 'Enabled',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            values: {
                'All Players': null,
                'Enabled Players': true,
                'Disabled Players': false
            }
        },
    ]
};

export const ModelSearchPlayerWithBalance = {
    name: 'search-players-with-balance',
    fields: [

        {
            name: 'Id',
            type: InputTypes.Number
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Email',
            title: 'Email',
            type: InputTypes.Text,
            hidden: true,
            exact: true
        },
        {
            name: 'Enabled',
            title: 'Enabled',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            values: {
                'All Players': null,
                'Enabled Players': true,
                'Disabled Players': false
            }
        },
    ]
};



export const ModelSearchNewPlayer = {
    name: 'search-new-players',
    fields: [
        {
            name: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Created',
            title: 'Register Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'IdNumber',
            title: 'Id / Passport',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Email',
            title: 'Email',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Phone',
            title: 'Phone',
            type: InputTypes.Text
        },
        {
            name: 'Tag',
            title: 'Tags',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last Login Ip',
            type: InputTypes.Text,
            hidden: true,
        },
        {
            name: 'Enabled',
            title: 'Enabled',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            hidden: true,
            values: {
                'All Players': null,
                'Enabled Players': true,
                'Disabled Players': false
            }
        },
    ]
};


export const ModelSearchAffiliatePlayers = {
    name: 'search-players',
    fields: [
        {
            name: 'AffiliateId',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myAffiliates',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'IdNumber',
            title: 'Id / Passport',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Email',
            title: 'Email',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Phone',
            title: 'Phone',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Tag',
            title: 'Tags',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last Login Ip',
            type: InputTypes.Text,
            hidden: true,
        },
        {
            name: 'Enabled',
            title: 'Enabled',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            hidden: true,
            values: {
                'All Players': null,
                'Enabled Players': true,
                'Disabled Players': false
            }
        },
    ]
};


export const ModelSearchRetailPlayers = {
    name: 'search-players',
    fields: [
        {
            name: 'RetailId',
            hidden: true
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'IdNumber',
            title: 'Id / Passport',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Email',
            title: 'Email',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Phone',
            title: 'Phone',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Tag',
            title: 'Tags',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last Login Ip',
            type: InputTypes.Text,
            hidden: true,
        },
        {
            name: 'Enabled',
            title: 'Enabled',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            hidden: true,
            values: {
                'All Players': null,
                'Enabled Players': true,
                'Disabled Players': false
            }
        },
    ]
};

export const ModelPlayerSummary = {
    fields: [
        {
            name: 'Id',
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'Operator',
            notExportable: true
        },
        {
            name: 'PlayerGroup',
            title: 'Player Group'
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            notExportable: true
        },
        {
            name: 'Created',
            title: 'Register Date',
            type: InputTypes.DateTime,
            hidden: true
        },
        {
            name: 'RetailName',
            title: 'Retail Name'
        },
        {
            name: 'Affiliate',
            title: 'Affiliate'
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Email',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Phone',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Country',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'City',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'Tag',
            title: 'Tags',
            hidden: true
        },
        {
            name: 'BirthDay',
            type: InputTypes.Date,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last IP',
            notExportable: true
        },
        {
            name: 'LastLogin',
            title: 'Last Login Date',
            type: InputTypes.DateTime,
            notExportable: true
        },
        {
            name: 'RolloverLeft',
            title: 'Completed',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} />
        },
        {
            name: 'Balance',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} />
        }
    ]
};

export const ModelPlayerSummaryWithBalances = {
    fields: [
        {
            name: 'Id',
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'Created',
            title: 'Register Date',
            type: InputTypes.DateTime,
            hidden: true
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            render: (val, row) => {
                return openProfile(val, row.Id);
            }
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Email',
            type: InputTypes.Text,
            hidden: true
        },
        {

            name: 'Balance',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {

            name: 'BonusBalance',
            type: InputTypes.Number,
        }
    ]
};

export const ModelAffiliatePlayer = {
    fields: [

        {
            name: 'Id',
            type: InputTypes.Number
        },
        {
            name: 'Created',
            title: 'Register Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Affiliate',
            title: 'Affiliate'
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Country',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'City',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last IP'
        },
        {
            name: 'LastLogin',
            title: 'Last Login Date',
            type: InputTypes.DateTime
        },
        {

            name: 'Balance',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};


export const ModelRetailPlayer = {
    fields: [

        {
            name: 'Id',
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'Created',
            title: 'Register Date',
            type: InputTypes.DateTime,
            hidden: true
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Country',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'City',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'LastLoginIp',
            title: 'Last IP'
        },
        {
            name: 'LastLogin',
            title: 'Last Login Date',
            type: InputTypes.DateTime
        },
        {

            name: 'Balance',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};