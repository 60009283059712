/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import { openProfile } from '../../../lib/Helpers/NavigationHelper';

export const searchModel = {
    fields: [
        {
            name: 'RemovedDate',
            title: 'Removed Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'promotion',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'ConsumedDate',
            title: 'Consumed Date',
            type: InputTypes.DateTime
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Code',
            title: 'Code',
            hidden: true
        },
        {
            name: 'Currency',
            title: 'Currency',
            hidden: true
        },
        {
            name: 'Username',
            render: (val, row) => {
                return openProfile(val,row.PlayerId);
            }
        },
        {
            name: 'PlayerCode',
            title: 'Player Code',
            hidden: true
        },
        {
            name: 'Group',
            title: 'Player Group'
        },
        {
            name: 'Promotion',
            title: 'Promotion'
        },
        {
            name: 'PlayerBalance',
            title: 'Balance Before Bonus',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={true} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'TotalBonus',
            title: 'Bonus Amount',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={true} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'Amount',
            title: 'Deducted Amount',
            render: (val, row) => <Currency amount={Math.abs(val)} sign={row.Currency} showSign={true} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'GivenBy',
            title: 'Given By'
        },
        {
            name: 'RemovedBy',
            title: 'Deducted By'
        }
    ]
};

export const ReportDeductedBonusAmountsReport = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.DeductedBonusReports}
        resultModel={resultModel}
    />;
};
