/* eslint-disable react/display-name */
import React from 'react';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { POSEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { toSnakeCase } from '../../../../components/Centrum/helpers';
import { Currency } from '../../../components/currency/Currency';


export const SearchPOSUserTransactions = () => {
    const model = {
        fields: [
            {
                name: 'Username',
                title: 'Username'
            },
            {
                name: 'EntryDate',
                title: 'Date',
                type: InputTypes.DateTime,
                between: true,
                value: {
                    start: DateHelpers.yesterday(),
                    end: DateHelpers.tonight()
                }
            },
            {
                name: 'Id',
                title: 'Transaction Id'
            },
            {
                name: 'Type',
                title: 'Type',
                type: InputTypes.Select,
                nullable: true,
                multiple: true,
                exact: true,
                value: [0, 1],
                values: {
                    0: 'Deposit',
                    1: 'Withdraw',
                    3: 'Withdraw from Player',
                    2: 'Deposit to Player'
                }
            },
            {
                name: 'ProviderId',
                title: 'Provider',
                type: InputTypes.Select,
                nullable: true,
                //value: [191341, 191327],
                data: {
                    source: 'providers/payment',
                    key: 'id',
                    title: ['display'],
                    parser: (result) => {
                        var results = [];
                        var group = 'Gaming';
                        result.result.map(x => {
                            x = toSnakeCase(x);
                            if (x.type == 128) group = 'Payment Provider';
                            results.push({
                                value: x.id, title: x.display, group: {
                                    id: group,
                                    title: group
                                }
                            });
                        });
                        return results;
                    }
                }
            },
            {
                name: 'Status',
                title: 'Status',
                type: InputTypes.Select,
                nullable: true,
                exact: true,
                value: 1,
                values: {
                    1: 'Confirmed',
                    2: 'Cancelled',
                }
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'EntryDate',
                display: 'Date',
                type: InputTypes.DateTime
            },
            {
                name: 'Type',
                title: 'Type',
                type: InputTypes.Select,
                nullable: true,
                multiple: true,
                exact: true,
                value: [0, 1],
                values: {
                    0: 'Deposit',
                    1: 'Withdraw',
                    3: 'Withdraw from Player',
                    2: 'Deposit to Player'
                }
            },
            {
                name: 'Status',
                title: 'Status',
                type: InputTypes.Select,
                nullable: true,
                exact: true,
                value: 1,
                values: {
                    1: 'Confirmed',
                    2: 'Cancelled',
                }
            },
            {
                name: 'POSUser',
                title: 'POS User'
            },
            {
                name: 'UserId',
                title: 'Admin'
            },
            {
                name: 'Player',
                title: 'Player'
            },
            {
                name: 'Amount',
                render: (val, row) => {
                    return <Currency amount={val} sign={row.Currency} />;
                }
            },
            {
                name: 'BalanceBefore',
                title: 'Balance Before',
                render: (val, row) => {
                    return <Currency amount={val} sign={row.Currency} />;
                }
            },
            {
                name: 'BalanceAfter',
                title: 'Balance After',
                render: (val, row) => {
                    return <Currency amount={val} sign={row.Currency} />;
                }
            }
        ]
    };


    const onActionClicked = (action, user) => {
        switch (action.type) {
            default:
                console.log(action.type, user);
                break;
        }
    };

    return <FluxSearch title='Search POS Transactions' model={model} resultModel={resultModel} endpoint={POSEndpoints.SearchPOSUserTransactions} searchOnStart actions={ActionTypes.POSTransactions} onActionClicked={onActionClicked.bind(this)} />;
};