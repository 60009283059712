import './_sports-tree.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { API } from '../../../lib/API/Api';
import { FixtureEndpoints, SportEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { lang } from '../../../lib/Localization/language';
import { Button } from '../../button/Button';
import { Icon } from '../../icon/Icon';
import { Country } from '../../icons/country/Country';
import { SportIcon } from '../SportIcon/SportIcon';

export const SportsTree = (props) => {
    const enableRegions = props.regions;
    const enableLeagues = props.leagues;
    const enableTeams = props.teams;
    const enableEvents = props.events;
    const enableEventDates = props.eventDate;

    const [categories, setCategories] = useState(props.categories);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [events, setEvents] = useState(null);
    const [teams, setTeams] = useState(null);

    const [selectedCategory, selectCategory] = useState(null);
    const [selectedRegion, selectRegion] = useState(null);
    const [selectedLeague, selectLeague] = useState(null);
    const [selectedTeam, selectTeam] = useState(null);
    const [selectedEvent, selectEvent] = useState(null);

    const [eventDates, setEventDates] = useState(null);

    const [loading, setLoading] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null);
    const [searchFilterSportIds, setSearchFilterSportIds] = useState([]);
    const [searchFilterLeagueIds, setSearchFilterLeagueIds] = useState([]);
    const [searchFilterRegionIds, setSearchFilterRegionIds] = useState([]);
    const [searchFilterTeamIds, setSearchFilterTeamIds] = useState([]);

    useEffect(() => {
        if (!props.categories) {
            setLoading(true);
            API.post(SportEndpoints.Categories, null).then((result) => {
                var c = result.result.sort((a, b) => a.weight - b.weight);
                setCategories(c);
                if (props.onCategoriesLoaded) {
                    props.onCategoriesLoaded(c);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!categories) return;
        selectCategory(categories[0]);
    }, [categories]);

    useEffect(() => {
        if (!selectedCategory) return;
        if (!enableRegions) return;
        setLoading(true);
        API.post(`${SportEndpoints.Regions}/${selectedCategory.id}`).then((result) => {
            if (props.weights) {
                API.post(FixtureEndpoints.SetupSportRegions).then((regionWeights) => {
                    try {
                        var regionWeights = JSON.parse(regionWeights.result);
                        if (regionWeights[selectedCategory.id]) {
                            result.result.map(x => {
                                x.weight = regionWeights[selectedCategory.id][x.id]?.weight ?? 0;
                            });
                        }
                    } catch (err) {
                    }

                    setRegions(result.result.sort((b, a) => a.weight - b.weight));
                });
            } else {
                setRegions(result.result.sort((a, b) => a.weight - b.weight));
            }
            scrollCategoryToUp(selectedCategory.id);
            setLoading(false);
            if (props.onCategorySelected) {
                props.onCategorySelected(selectedCategory);
            }
        });
    }, [selectedCategory]);


    useEffect(() => {
        if (!selectedCategory) return;
        if (!selectedRegion) return;
        setLoading(true);
        API.post(`${SportEndpoints.Leagues}/${selectedCategory.id}/${selectedRegion.id}`).then((result) => {
            var l = result.result;
            if (props.onLeaguesFetched) {
                props.onLeaguesFetched(selectedCategory, selectedRegion, result.result)
            }
            setLeagues(l);
            // selectLeague(l.length > 0 ? l[0] : null);
            scrollCategoryToUp(selectedCategory.id);
            scrollRegionToUp(selectedRegion.id);
            setLoading(false);
        });
    }, [selectedRegion]);



    useEffect(() => {
        if (!selectedCategory) return;
        if (!selectedRegion) return;
        if (!selectedLeague) return;

        if (!enableTeams && !enableEvents) return;
        setLoading(true);

        if (enableTeams) {
            API.post(`${SportEndpoints.Teams}/${selectedCategory.id}/${selectedRegion.id}/${selectedLeague.id}`).then((result) => {
                var l = result.result;
                setTeams(l);
                if (props.onTeamsFetched) {
                    props.onTeamsFetched(selectedCategory, selectedRegion, selectedLeague, result.result)
                }
                // selectTeam(l.length > 0 ? l[0] : null);
                setLoading(false);
            });
        }
        if (!enableEvents) return;
        API.post(SportEndpoints.Events, {
            sportId: selectedCategory.id,
            regionId: selectedRegion.id,
            leagueId: selectedLeague.id
        }).then((result) => {
            var l = result.result.sort((a, b) => new Date(a.date) - new Date(b.date));
            setEvents(l);
            scrollCategoryToUp(selectedCategory.id);
            scrollRegionToUp(selectedRegion.id);
            scrollLeagueToUp(selectedLeague.id);
            setLoading(false);
        });
    }, [selectedLeague]);


    useEffect(() => {
        if (!events) {
            setEventDates({});
        } else {
            var dates = {};
            events.map(event => {
                if (event.outright) return;
                var date = new Date(event.date.indexOf('Z') > 0 ? event.date : event.date + 'Z');
                if (date < new Date()) return;
                var data = event.name.split(' vs ');
                var home = data[0];
                var away = data[1];
                var formatted = DateHelpers.getFormatedDateWithMonth(date);

                var key = `${formatted.month} ${formatted.year}, ${formatted.day}`;

                if (!dates[key]) {
                    dates[key] = [];
                }

                dates[key].push({
                    home: home,
                    away: away,
                    formatted: formatted,
                    data: event
                });
            });

            setEventDates(dates);
        }
    }, [events]);

    const renderTeams = () => {
        if (!enableLeagues) return;
        if (!teams || teams.length == 0) {
            return <items>
                <item className='padding'>
                    <span>{lang('No teams found')}</span>
                </item>
            </items>;
        }
        return <items>
            {(searchFilterTeamIds && searchFilterTeamIds.length > 0 ? teams?.filter(x => searchFilterTeamIds.includes(x.id)) : teams)?.map(x => {
                const selected = x == selectedTeam;
                return <item key={x}
                    className={`flex gap-5 tree-child${selected ? ' selected' : ''}`}
                    onClick={() => {
                        if (props.onTeamSelected) {
                            props.onTeamSelected(x);
                        }
                    }}
                ><span>{x.name}</span>{props.onRenderTeamOptions ? props.onRenderTeamOptions(x) : null}</item>;
            })}
        </items>;
    };

    const getEventFormattedDate = (date) => {
        if (!date) return date;
        if (!date.formatted) return date.formatted;
        const splitted = date.formatted.split(',');
        return splitted[0];
    }

    const renderEvents = () => {
        if (!enableEvents) return;
        return <items>
            {Object.keys(eventDates).map(dateData => {
                var dateValues = eventDates[dateData];
                return <React.Fragment key={dateData}>
                    <item><label className='bold'>{dateData}</label></item>
                    <items>
                        {
                            dateValues.map(x => {
                                var selected = x.data == selectedEvent;
                                return <item key={x}>
                                    <flex className={`${selected ? ' selected' : ''} gap-10 align-items-start`}
                                        onClick={() => {
                                            if (props.onEventSelected) {
                                                props.onEventSelected(x.data);
                                            }
                                            if (selectedEvent == x) {
                                                selectedEvent(null);
                                                return;
                                            }
                                            selectEvent(x.data);
                                        }}>
                                        <flex className='vertical'>
                                            {enableEventDates && <span className='small'>{getEventFormattedDate(x.formatted)}</span>}
                                            <span>{x.formatted.time}</span>
                                        </flex>
                                        <flex className='vertical'>
                                            <span>{x.home}</span>
                                            <span>{x.away}</span>
                                        </flex>
                                        <flex className='align-right gap-5'>
                                            {selected && <Icon icon='chevron-right' size='xs' className='toggle-icon' />}
                                            {props.onRenderEventOptions ? props.onRenderEventOptions(x.data) : null}
                                        </flex>
                                    </flex>
                                </item>;
                            })
                        }
                    </items>
                </React.Fragment>

            })}
        </items>;
    };

    const scrollToLeague = (letter) => {
        var elm = document.querySelector(`#league-alphabet-group-${letter}`);
        if (elm) {
            var container = elm.parentElement;

            var elementTop = elm.getBoundingClientRect().top;
            var containerTop = container.getBoundingClientRect().top;
            var offset = elementTop - containerTop;

            container.scrollTo({
                top: container.scrollTop + offset,
                behavior: 'smooth'
            });
        }
    }

    const scrollToRegion = (letter) => {
        var elm = document.querySelector(`#region-alphabet-group-${letter}`);
        if (elm) {
            var container = elm.parentElement;

            var elementTop = elm.getBoundingClientRect().top;
            var containerTop = container.getBoundingClientRect().top;
            var offset = elementTop - containerTop;

            container.scrollTo({
                top: container.scrollTop + offset,
                behavior: 'smooth'
            });
        }
    }

    const scrollCategoryToUp = (id) => {
        var elm = document.querySelector(`#category-${id}`);
        if (elm) {
            var container = elm.parentElement.parentElement.parentElement.parentElement;

            var elementTop = elm.getBoundingClientRect().top;
            var containerTop = container.getBoundingClientRect().top;
            var offset = elementTop - containerTop;

            container.scrollTo({
                top: container.scrollTop + offset - 50,
                behavior: 'smooth'
            });
        }
    }

    const scrollRegionToUp = (id) => {
        var elm = document.querySelector(`#region-${id}`);
        if (elm) {
            var container = elm.parentElement.parentElement;

            var elementTop = elm.getBoundingClientRect().top;
            var containerTop = container.getBoundingClientRect().top;
            var offset = elementTop - containerTop;

            container.scrollTo({
                top: container.scrollTop + offset,
                behavior: 'smooth'
            });
        }
    }

    const scrollLeagueToUp = (id) => {
        var elm = document.querySelector(`#league-${id}`);
        if (elm) {
            var container = elm.parentElement.parentElement;

            var elementTop = elm.getBoundingClientRect().top;
            var containerTop = container.getBoundingClientRect().top;
            var offset = elementTop - containerTop;

            container.scrollTo({
                top: container.scrollTop + offset,
                behavior: 'smooth'
            });
        }
    }

    const renderLeague = (x, normalView = false) => {
        var selected = x == selectedLeague || x?.id == selectedLeague?.id;
        return <React.Fragment key={x.id}>
            <item id={`league-${x.id}`} onClick={() => {
                if (props.onLeagueSelected) {
                    props.onLeagueSelected(x);
                }
                if (x == selectedLeague || x?.id == selectedLeague?.id) {
                    selectLeague(null);
                    return;
                }
                selectLeague(x);
            }}>
                <flex className={`gap-10 node ${selected ? ' selected' : ''} ${normalView ? 'normal-view' : ''}`}>
                    <span>{x.name}</span>
                    <flex className='align-right gap-5'>
                        {selected && (enableTeams || enableEvents) && <Icon icon='chevron-down' size='xs' className='toggle-icon' />}
                        {props.onRenderLeagueOptions ? props.onRenderLeagueOptions(x) : null}
                    </flex>
                </flex>
            </item>
            {selected && enableTeams && renderTeams()}
            {selected && enableEvents && renderEvents()}
        </React.Fragment>;
    }

    const renderLeagues = () => {
        if (!enableLeagues) return;
        var data = leagues ?? [];
        if (searchFilterLeagueIds && searchFilterLeagueIds.length > 0) {
            data = data.filter(x => searchFilterLeagueIds.includes(x.id));
        }

        data = data.map(obj => ({
            ...obj,
            name: obj.name.trimStart()
        }));
        const groupedData = data.reduce((groups, league) => {
            const firstLetter = league.name[0].toUpperCase();
            if (!groups[firstLetter]) {
                groups[firstLetter] = [];
            }
            groups[firstLetter].push(league);
            return groups;
        }, {});

        if (data.length <= 20) {
            return <items>
                {data.map(x => {
                    return renderLeague(x, true);
                })}
            </items>;
        } else {
            //todo if less than 20 item, display direct list
            return <flex className="two-column-layout">
                <item className="alphabet-column">
                    {Object.keys(groupedData)
                        .sort()
                        .map(letter => {
                            return <item key={letter} className='alphabet-item' onClick={() => scrollToLeague(letter)}>
                                {letter}
                            </item>
                        })}
                </item>
                <items className="grouped-items-column">
                    {Object.keys(groupedData)
                        .sort()
                        .map(letter => (
                            <div key={letter} id={`league-alphabet-group-${letter}`} className="group-section">
                                <items className="group-header">{letter}</items>
                                {groupedData[letter].map(x => {
                                    return renderLeague(x);
                                })}
                            </div>
                        ))}
                </items>
            </flex>;
        }
    };

    const renderRegion = (selected, x, normalView = false) => {
        return <React.Fragment key={x.id}>
            <item id={`region-${x.id}`} onClick={() => {
                if (props.onRegionSelected) {
                    props.onRegionSelected(selectedCategory, x);
                }

                if (selectedRegion == x) {
                    selectRegion(null);
                    return;
                }
                selectRegion(x);
            }}>
                <flex className={`gap-10 node ${selected ? ' selected' : ''} ${normalView ? 'normal-view' : ''}`}>
                    <flex className='gap-5'><Country name={x.name} circular /> <span>{x.name}</span></flex>
                    <flex className='align-right gap-5'>
                        {selected && <Icon icon='chevron-down' size='xs' className='toggle-icon' />}
                    </flex>
                </flex>
            </item>
            {selected && renderLeagues()}
        </React.Fragment>;
    }

    const renderRegions = () => {
        if (!enableRegions) return;
        var data = regions ?? [];
        if (searchFilterRegionIds && searchFilterRegionIds.length > 0) {
            data = data.filter(x => searchFilterRegionIds.includes(x.id))
        }

        const groupedData = data.reduce((groups, region) => {
            const firstLetter = region.name[0].toUpperCase();
            if (!groups[firstLetter]) {
                groups[firstLetter] = [];
            }
            groups[firstLetter].push(region);
            return groups;
        }, {});

        //todo if less than 20 item, display direct list
        if (data.length <= 20) {
            return <items className='normal-view'>
                {data.map(x => {
                    var selected = x == selectedRegion;
                    return renderRegion(selected, x, true);
                })}
            </items>;
        } else {
            return <flex className="two-column-layout">
                <item className="alphabet-column">
                    {Object.keys(groupedData)
                        .sort()
                        .map(letter => {
                            return <item key={letter} className='alphabet-item' onClick={() => scrollToRegion(letter)}>
                                {letter}
                            </item>
                        })}
                </item>
                <items className="grouped-items-column">
                    {Object.keys(groupedData)
                        .sort()
                        .map(letter => (
                            <div key={letter} id={`region-alphabet-group-${letter}`} className="group-section">
                                <items className="group-header">{letter}</items>
                                {groupedData[letter].map(region => {
                                    const selected = region === selectedRegion;
                                    return renderRegion(selected, region);
                                })}
                            </div>
                        ))}
                </items>
            </flex >;
        }
    };

    const searchInTree = () => {
        if (props.onSearch) {
            props.onSearch(searchFilter);
        }
        if (!searchFilter || searchFilter.length < 3) {
            setSearchFilterSportIds([]);
            setSearchFilterLeagueIds([]);
            setSearchFilterRegionIds([]);
            setSearchFilterTeamIds([]);
            return
        };
        setLoading(true);
        API.post(SportEndpoints.SearchInTree, {
            filter: searchFilter,
            enableTeams: enableTeams ?? false,
            enableEvents: enableEvents ?? false
        }).then((result) => {
            if (result.status == 1) {
                var sportIds = [];
                var leagueIds = [];
                var regionIds = [];
                var teamIds = [];

                for (let i = 0; i < result.result.length; i++) {
                    const element = result.result[i];
                    leagueIds.push(element.item1);
                    regionIds.push(element.item2);
                    sportIds.push(element.item3);
                    teamIds.push(element.item5);
                }

                const distinctLeagueIds = [...new Set(leagueIds)].filter(x => x != 0);
                const distinctRegionIds = [...new Set(regionIds)].filter(x => x != 0);
                const distinctSportIds = [...new Set(sportIds)].filter(x => x != 0);
                const distinctTeamIds = [...new Set(teamIds)].filter(x => x != 0);
                setSearchFilterLeagueIds(distinctLeagueIds);
                setSearchFilterRegionIds(distinctRegionIds);
                setSearchFilterSportIds(distinctSportIds);
                setSearchFilterTeamIds(distinctTeamIds);
            }

            setLoading(false);
        });
    }

    return <div className='sport-tree-fragment' id={'sport-tree-items'}>
        <fluxList className='flux-tree sport-tree'>
            <flex flex className='padding border-bottom fit-children search-in-tree fx-shadow gap-5' >
                <input type='text' className='w-100' placeholder={lang('Search in tree')} defaultValue={null}
                    onChange={(e) => setSearchFilter(e.target.value)} />
                <Button className={'success align-right'} title={'Search'} onClick={() => searchInTree()} />
            </flex>
            <items>
                {
                    (searchFilterSportIds && searchFilterSportIds.length > 0 ?
                        categories?.filter(x => searchFilterSportIds.includes(x.id) ?? [])
                        : categories)
                        ?.map(x => {
                            var selected = x == selectedCategory;
                            return <React.Fragment key={x}>
                                <item id={`category-${x.id}`} className={`${selected ? ' selected' : ''}`}
                                    onClick={() => {
                                        if (selectedCategory == x) {
                                            selectCategory(null);
                                            return;
                                        }
                                        selectCategory(x);
                                    }}>
                                    <flex className='gap-10'>
                                        <flex className='gap-5'><SportIcon sport={x.name} /><span>{x.name}</span></flex>
                                        <flex className='align-right gap-5'>
                                            {selected && <Icon icon='chevron-down' size='xs' className='toggle-icon' />}
                                        </flex>
                                    </flex>
                                </item>
                                {selected && renderRegions()}
                            </React.Fragment>
                        })}
            </items>
        </fluxList >
    </div>;
};

SportsTree.propTypes = {
    categories: PropTypes.object,
    regions: PropTypes.bool,
    leagues: PropTypes.bool,
    teams: PropTypes.bool,
    events: PropTypes.bool,

    searchEnabled: PropTypes.bool,

    weights: PropTypes.bool,

    onCategoriesLoaded: PropTypes.func,

    onCategorySelected: PropTypes.func,
    onRegionSelected: PropTypes.func,
    onLeagueSelected: PropTypes.func,
    onEventSelected: PropTypes.func,
    onTeamSelected: PropTypes.func,

    onRenderLeagueOptions: PropTypes.func,
    onRenderTeamOptions: PropTypes.func,
    onRenderEventOptions: PropTypes.func,

    onLeaguesFetched: PropTypes.func,
    onTeamsFetched: PropTypes.func,

    onSearch: PropTypes.func,
};