import React from 'react';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { FluxTab } from '../../../components/tab/FluxTab';
import { CashbackBonusPlayerGroupSettings } from './Components/CashbackBonus/CashbackBonusPlayerGroupSettings';
import { CashbackBonusSettings } from './Components/CashbackBonus/CashbackBonusSettings';
import { GeneralSettings } from './Components/GeneralSettings';
import { SportsSettings } from './Components/SportsSettings';
import { FirstTimeFreeBetSettings } from './Components/FirstTimeFreeBetSettings';

export const CashbackBonus = (props) => {
    const { promotion } = { ...props };
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Rules'
        },
        {
            title: 'Groups'
        },
        {
            title: 'Sports'
        },
        {
            title: 'Free Bet Settings'
        }
    ];


    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
        <CashbackBonusSettings {...props} />
        <CashbackBonusPlayerGroupSettings {...props} />
        <SportsSettings {...props}
            disabled={(
                promotion?.Configuration?.cashbackType != '2'
                && promotion?.Configuration?.cashbackType != '3'
                && promotion?.Configuration?.cashbackType != '4'
                && promotion?.Configuration?.cashbackType != '5'
            )}
            disabledReason={<FluxCard>
                <FluxNotice type='information' title='Sport configuration is not enabled' description={'Configuration should be completed using the selected cashback type settings from Rules tab!'} />
            </FluxCard>} />
        <FirstTimeFreeBetSettings  {...props}
            disabled={(
                promotion?.Configuration?.cashbackType != '2'
                && promotion?.Configuration?.cashbackType != '3'
                && promotion?.Configuration?.cashbackType != '4'
                && promotion?.Configuration?.cashbackType != '5'
            )}
            disabledReason={<FluxCard>
                <FluxNotice type='information' title='Sport configuration is not enabled' description={'Configuration should be completed using the selected cashback type settings from Rules tab!'} />
            </FluxCard>} />
    </FluxTab>;
};