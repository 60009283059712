/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { readFromFile, saveXLSX } from '../../../lib/Helpers/Excel/ExcelHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';

import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import './_report-daily-revenues.scss';


const calculateWinLoss = (row, type) => {
    if (!row[`${type}Bets`]) row[`${type}Bets`] = 0;
    if (!row[`${type}Win`]) row[`${type}Win`] = 0;
    if (!row[`${type}BetsBonus`]) row[`${type}BetsBonus`] = 0;
    if (!row[`${type}WinBonus`]) row[`${type}WinBonus`] = 0;

    var bets = (Math.abs(row[`${type}Bets`]) + Math.abs(row[`${type}BetsBonus`]));
    var win = (row[`${type}Win`] + row[`${type}WinBonus`]);
    var profit = bets - win;

    var perc = profit / bets;
    if (isNaN(perc)) perc = 0;
    if (bets == 0) perc = 0;
    return '%' + perc.toFixed(2);

};


export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Date',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        },
        {
            name: 'IncludeChildRetails',
            title: 'Include Child Retails',
            type: InputTypes.Bool
        },
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Date',
            title: 'Date',
            render: (val, row,) => {
                if (!row.Date) return;
                return new Date(row.Date.split('T')[0] + 'T23:59:59').toLocaleDateString('en-GB');
            }
        },

        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Deposit',
            title: 'Total Deposit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Total</span>
                    <span className='center'>Deposit</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Withdraw',
            title: 'Total Withdraw',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Total</span>
                    <span className='center'>Withdraw</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusDeposit',
            title: 'Given Bonus',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Given</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportTickets',
            title: 'Sport Tickets',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Tickets</span>
                </div>;
            }
        },
        {
            name: 'SportTicketsBonus',
            title: 'Sport Bonus Tickets',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportBets',
            title: 'Sport Stakes',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportWin',
            title: 'Sport Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Win</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportBetsBonus',
            title: 'Sport Bonus Stake',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stake</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportBonusWin',
            title: 'Sport Bonus Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportGGR',
            title: 'Sport Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>Profit</span>
                </div>;
            },
            render: (val, row) => <Currency amount={row.SportBets - row.SportWin} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportWinLoss',
            title: 'Sport Win/Loss',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Sport</span>
                    <span className='center'>W/L</span>
                </div>;
            },
            render: (val, row) => {
                return calculateWinLoss(row, 'Sport');
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseTickets',
            title: 'Horse Tickets',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseTicketsBonus',
            title: 'Horse Bonus Tickets',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Tickets</span>
                </div>;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseBets',
            title: 'Horse Stakes',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseWin',
            title: 'Horse Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Win</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseBetsBonus',
            title: 'Horse Bonus Stake',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseWinBonus',
            title: 'Horse Bonus Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseGGR',
            title: 'Horse Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>Profit</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'HorseWinLoss',
            title: 'Horse Win/Loss',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Horse</span>
                    <span className='center'>W/L</span>
                </div>;
            },
            render: (val, row) => {
                return calculateWinLoss(row, 'Horse');
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoGames',
            title: 'Casino Games',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Games</span>
                </div>;
            }
        },
        {
            name: 'CasinoGamesBonus',
            title: 'Casino Bonus Games',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Bonus</span>
                    <span className='center'>Games</span>
                </div>;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoBets',
            title: 'Casino Stakes',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Stakes</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoWin',
            title: 'Casino Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Win</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoCancel',
            title: 'Casino Cancel',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Cancel</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoBetsBonus',
            title: 'Casino Bonus Stake',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Bonus</span>
                    <span className='center'>Stake</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoWinBonus',
            title: 'Casino Bonus Win',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Bonus</span>
                    <span className='center'>Win</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoCancelBonus',
            title: 'Casino Bonus Cancel',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Bonus</span>
                    <span className='center'>Cancel</span>
                </div>;
            },
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoGGR',
            title: 'Casino Profit',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>Profit</span>
                </div>;
            },
            render: (val, row) => <Currency amount={row.CasinoBets - row.CasinoWin} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoWinLoss',
            title: 'Casino Win/Loss',
            renderHeader: () => {
                return <div className='flex vertical'>
                    <span className='center'>Casino</span>
                    <span className='center'>W/L</span>
                </div>;
            },
            render: (val, row) => {
                return calculateWinLoss(row, 'Casino');
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ],
    onParseExcel: (data) => {
        readFromFile('/report-files/summary.xlsx').then((workbook) => {
            var rawReportSheet = workbook.getWorksheet('By Currencies');

            var currencies = data.data.rates;
            var defaultCurrency = data.data.defaultCurrency;
            var currencySheet = workbook.getWorksheet('Currency Rates');
            var reportSheet = workbook.getWorksheet('Report');

            const convert = (amount, source) => {
                if (source == defaultCurrency) return amount;
                var rate = currencies.find(x => x.base == defaultCurrency && x.currencyCode == source);
                return amount * rate.buy;
            };

            var testAccounts = data.request.fields.find(x => x.name == 'TestAccounts');
            if (testAccounts.value == '0') testAccounts = 'Excluded';
            if (testAccounts.value == '1') testAccounts = 'Included';
            if (testAccounts.value == '2') testAccounts = 'Only test accounts';


            reportSheet.getCell('A1').value = 'Daily revenue summary report';
            reportSheet.getCell('B2').value = new Date();
            reportSheet.getCell('B3').value = defaultCurrency;
            reportSheet.getCell('B4').value = new Date(data.request.fields.find(x => x.name == 'Date').min);
            reportSheet.getCell('C4').value = new Date(data.request.fields.find(x => x.name == 'Date').max);
            reportSheet.getCell('B5').value = testAccounts;

            var convertedRows = {};
            currencies.map(x => {
                var row = [];
                if (x.base == defaultCurrency) {
                    //ws.getCell('A1').numFmt = '# ?/?';
                    row.push(1);
                    row.push(x.currencyCode);
                    row.push(x.buy);
                    row.push(defaultCurrency);
                    currencySheet.insertRow(5, row);
                }
            });

            data.result.map(x => {
                var row = [];
                var reportRow = [];
                if (!convertedRows.hasOwnProperty(x.Date)) {
                    convertedRows[x.Date] = {};
                }

                data.model.fields.map(field => {
                    var value = x[field.name];
                    if (field.type == InputTypes.date) {
                        value = new Date(value);
                    } else {
                        value = field.formatter ? field.formatter(value, x, false, true) : value;
                    }
                    if (!convertedRows[x.Date].hasOwnProperty(field.name)) {
                        convertedRows[x.Date][field.name] = 0;
                    }
                    if (field.currency) {
                        value = convert(value, x.Currency);
                        convertedRows[x.Date][field.name] += value;
                    } else {
                        if (field.type == InputTypes.number) {
                            convertedRows[x.Date][field.name] += value;
                        } else {
                            convertedRows[x.Date][field.name] = value;
                        }
                    }
                    reportRow.push(value);
                    row.push(value);
                });
                rawReportSheet.insertRow(3, row, 'o');
            });


            Object.values(convertedRows).map(x => {
                delete x.Currency;
                reportSheet.insertRow(11, Object.values(x), 'o');
            });

            saveXLSX(workbook, 'daily-summary.xlsx');
        });
    }
};


export const ReportDailyRevenues = () => {
    const user = useSelector((state) => state.user.loginInformation);
    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;

    return <FluxSearch
        tableClassName={'daily-revenues-table'}
        model={searchModel}
        endpoint={ReportEndpoints.DailyRevenuesSummary}
        resultModel={resultModel}
        exportAllData={true}
    />;
};
