/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { RetailPlayerTransactionTypes } from '../../../lib/Enums/RetailEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { openProfile } from '../../../lib/Helpers/NavigationHelper';

export const searchModel = {
    fields: [
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            values: RetailPlayerTransactionTypes,
            nullable: true,
        },
        {
            name: 'Code',
            type: InputTypes.Text,
        },
        {
            name: 'PlayerCode',
            title: 'Player Code',
            type: InputTypes.Text,
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Id',
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Code',
            title: 'Retail Code'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'PlayerCode',
            title: 'Player Code'
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return openProfile(val,row.PlayerId);
            }
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Type',
            type: InputTypes.Select,
            values: RetailPlayerTransactionTypes,
            render: (val) => {
                return RetailPlayerTransactionTypes[val];
            }
        },
        {
            name: 'PlayerCode',
            title: 'Player Code'
        },
        {
            name: 'Amount',
            title: 'Amount',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BalanceBefore',
            title: 'Balance Before',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Balance',
            title: 'Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};

export const ReportPlayerTransactionsRetails = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.RetailPlayerTransactionReport}
        resultModel={resultModel}
    />;
};
