/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { InputTypes } from '../../../../components/form/InputTypes';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { NotificationType } from '../../../../components/notification/FluxNotification';
import { Popper } from '../../../../components/notification/FluxPopper';
import { ToggleButton } from '../../../../components/toggleButton/ToggleButton';
import { API } from '../../../../lib/API/Api';
import { FixtureEndpoints, SportEndpoints } from '../../../../lib/API/Endpoints';
import { Table } from '../../../../../flux/components/table/Table';
import { SportsTree } from '../../../../components/sports/SportsTree/SportsTree';
import { LoadingIndicator } from '../../../../../components/shared/LoadingIndicator/LoadingIndicator';
import { FluxResizableCard } from '../../../../components/resizableCard/FluxResizableCard';

export const SetupLeagues = () => {
    const [categories, setCategories] = useState(null);

    const [weights, setWeights] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [region, selectRegion] = useState(null);
    let weightsCopy = { ...weights };


    useEffect(() => {
        API.post(FixtureEndpoints.SetupSportLeagues).then((weightResult) => {
            var w = {};
            try {
                w = JSON.parse(weightResult.result);
            } catch (err) {
                //
                w = {};
            }


            API.post(SportEndpoints.Categories).then((result) => {

                var copy = [...result.result];
                copy.map(x => {
                    if (w.hasOwnProperty(x.id)) {
                        x.weight = w[x.id].weight;
                    }
                });

                var sorted = Object.assign([], copy).sort((a, b) => { return a.weight - b.weight; });

                setCategories(sorted);
                setWeights(w);
            });

        });

    }, []);

    useEffect(() => {
        if (!region) return;
        setLeagues(null);
        API.post(`${SportEndpoints.Leagues}/${region.category}/${region.id}`).then((result) => {
            var l = result.result;
            l.map(x => {
                if (weights[`${region.category}_${region.id}`] && weights[`${region.category}_${region.id}`][x.id]) {
                    var weight = weights[`${region.category}_${region.id}`][x.id];
                    x.enabled = weight.enabled;
                    x.weight = weight.weight;
                } else {
                    x.enabled = true;
                }
            });
            setLeagues(l.sort((a, b) => b.weight - a.weight));
        });
    }, [region]);



    const save = () => {
        Modal.open(<ConfirmationModal title='Confirm operation' onConfirm={() => {
            API.post(FixtureEndpoints.SaveSetupSportLeagues, weightsCopy).then(() => {
                Popper.pop(NotificationType.Information, 'Fixture Saved!', 'Leagues saved, all changes are effective.');
            });
        }}>
            <FluxNotice type={NotificationType.Information} title='Please confirm save!' description={'The order of leagues & enabled properties will be effective active save.'} />
        </ConfirmationModal>);
    };

    if (!categories) return <></>;


    return <flex className='vertical gap-10 overflow-hidden'>
        <FluxResizableCard minLeftWidth={309}>
            <FluxCard title='Categories' className={'sports-tree-card-limit no-padding overflow-hidden'}>
                <SportsTree
                    data={categories}
                    regions
                    weights
                    onRegionSelected={(c, r) => {
                        selectRegion({
                            category: c.id,
                            id: r.id,
                            name: r.name
                        });
                    }}
                />
            </FluxCard>
            <FluxCard title='Manage Leagues' buttons={<flex className='align-right'><Button className='success' title='Save' onClick={() => save()} /></flex>}>
                {!leagues && !region && <FluxNotice type='information' title='League not selected' description={'Please select a league from the list'} />}
                {!leagues && region && <LoadingIndicator />}
                {leagues && <Table
                    data={leagues}
                    context={{
                        toggleEnabled: (row, value) => {
                            if (value == row.enabled) return;

                            if (!weightsCopy[`${region.category}_${region.id}`]) {
                                weightsCopy[`${region.category}_${region.id}`] = {};
                            }
                            if (!weightsCopy[`${region.category}_${region.id}`][row.id]) {
                                weightsCopy[`${region.category}_${region.id}`][row.id] = row;
                            }

                            var copy = [...leagues];
                            weightsCopy[`${region.category}_${region.id}`][row.id].enabled = value;
                            copy.find(x => x.id == row.id).enabled = value;
                            setLeagues(copy);
                            setWeights(weightsCopy);
                        }
                    }}
                    model={{
                        fields: [
                            {
                                name: 'id',
                                title: 'Id'
                            },
                            {
                                name: 'name',
                                title: 'Name'
                            },
                            {
                                name: 'weight',
                                title: 'Weight'
                            },
                            {
                                name: 'enabled',
                                title: 'Enabled',
                                type: InputTypes.Bool,
                                render: (val, row, field, context) => {
                                    return <ToggleButton value={row.enabled} onChange={(value) => {
                                        if (row.enabled === value) return;
                                        context.toggleEnabled(row, value);
                                    }} />;
                                }
                            }
                        ]
                    }}
                    options={{
                        data: {
                            delete: {
                                enabled: false,
                                header: false
                            }
                        },
                        draggable: {
                            enabled: true
                        }
                    }}
                    onSortEnd={(row, index, value) => {
                        if (!weightsCopy[`${region.category}_${region.id}`]) {
                            weightsCopy[`${region.category}_${region.id}`] = {};
                        }
                        value.map((x, i) => {
                            x.weight = value.length - i;
                            weightsCopy[`${region.category}_${region.id}`][x.id] = x;
                        });
                        setLeagues(value);
                    }}
                />}
            </FluxCard>
        </FluxResizableCard>

    </flex>;
};