/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';
import { openProfile } from '../../../lib/Helpers/NavigationHelper';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'ConsumedDate',
            title: 'Start Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'promotion',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'ConsumedDate',
            title: 'Start Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Code',
            title: 'Code'
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return openProfile(val,row.PlayerId);
            }
        },
        {
            name: 'PlayerCode',
            title: 'Player Code'
        },
        {
            name: 'Group',
            title: 'Player Group'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'PromotionName',
            title: 'Promotion'
        },
        {
            name: 'TurnoverMultiplier',
            title: 'Turnover Multiplier'
        },
        {
            name: 'SportsBets',
            title: 'Bets'
        },
        {
            name: 'Amount',
            title: 'Bonus Amount',
        },
        {
            name: 'TotalBet',
            title: 'Total Stake',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'TotalWin',
            title: 'Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'PlayerBalance',
            title: 'Starting Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusBalance',
            title: 'Bonus Balance'
        }
    ]
};

export const ReportBonusPlayers = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.BonusPlayersReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
