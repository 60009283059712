import './_top-leagues.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { FormModal } from '../../../../components/modal/FormModal/FormModal';
import { Popper } from '../../../../components/notification/FluxPopper';
import { SearchLeagues } from '../../../../components/sports/SearchLeagues/SearchLeagues';
import { SportsTree } from '../../../../components/sports/SportsTree/SportsTree';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { Table } from '../../../../components/table/Table';
import { API } from '../../../../lib/API/Api';
import { SportEndpoints } from '../../../../lib/API/Endpoints';
import { lang } from '../../../../lib/Localization/language';
import { FluxResizableCard } from '../../../../components/resizableCard/FluxResizableCard';

const topLeaguesModel = {
    fields: [
        {
            name: 'weight',
            title: 'Weight'
        },
        {
            name: 'league',
            title: 'League',
            render: (val, row) => {
                return <flex className='vertical'>
                    <span className='medium bold'>{row.league}</span>
                    {row.sport + '/' + row.region}
                </flex>;
            }
        },
        {
            name: 'tags',
            title: 'Tags'
        }
    ]
};


const topLeaguesLiveModel = {
    fields: [
        {
            name: 'weight',
            title: 'Weight'
        },
        {
            name: 'league',
            title: 'League',
            render: (val, row) => {
                return <flex className='vertical'>
                    <span className='medium bold'>{row.league}</span>
                    {row.sport + '/' + row.region}
                </flex>;
            }
        },
        {
            name: 'tags',
            title: 'Tags'
        }
    ]
};



export const SportTopLeagues = () => {
    const [categories, setCategories] = useState(null);
    const [topLeagues, setTopLeagues] = useState(null);
    const [topLeaguesLive, setTopLeaguesLive] = useState(null);
    const [selectedTreeType, selectTreeType] = useState(0);

    const [liveSports, setLiveSports] = useState(null);
    const [selectedLiveSport, selectLiveSport] = useState(null);
    const [liveUpdateKey, setLiveUpdateKey] = useState(0);

    useEffect(() => {
        API.post(SportEndpoints.Categories).then((result) => {
            setCategories(result.result.sort((a, b) => a.weight - b.weight));
        });
    }, []);

    useEffect(() => {
        if (!categories) return;
        var prematch = [];
        var live = [];
        API.post(SportEndpoints.GetTopLeagues, {}, 'fetching top leagues', 'unable to fetch top leagues').then((result) => {
            try {
                prematch = JSON.parse(result.result);
                if (!prematch) prematch = [];
            } catch (err) {
                //
            }

            API.post(SportEndpoints.GetLiveTopLeagues, {}, 'fetching top leagues', 'unable to fetch top leagues').then((result) => {
                try {
                    live = JSON.parse(result.result);
                    if (!live) live = [];
                } catch (err) {
                    //
                }

                prematch.map((x, index) => {
                    x.weight = prematch.length - index;
                });
                setTopLeagues(prematch);
                setTopLeaguesLive(live);
            });

        });
    }, [categories]);

    useEffect(() => {
        if (!topLeaguesLive) return;
        if (categories.length == 0) return;
        var copy = {};
        topLeaguesLive.map(x => {
            if (!copy[x.sport]) {
                copy[x.sport] = {
                    weight: categories.find(y => y.id == x.sportId).weight,
                    name: x.sport,
                    id: x.sportId,
                    items: []
                };
            }

            copy[x.sport].items.push(x);
            // TODO: Time complexty of this is N^2 (since we iterate every time the whole array when we add new item)
            // copy[x.sport].items.map((y, index) => {
            //     y.weight = copy[x.sport].items.length - index;
            // });
        });
        var datas = [];
        // TODO: Time complexty of this is N
        Object.values(copy).map(x => {
            if (x.items.length) {
                var count = x.items.length;
                x.items.map((y, index) => {
                    y.weight = count - index;
                });
                datas[x.name] = x;
            }
        });
        setLiveSports(datas);
        if (!selectedLiveSport && topLeaguesLive.length) {
            var first = Object.values(datas).sort((a, b) => a.weight > b.weight)[0].name;
            selectLiveSport(first);
        }

    }, [topLeaguesLive]);


    const save = () => {
        var targetTree = selectedTreeType == 0 ? topLeagues : topLeaguesLive;

        if (selectedTreeType == 1) {
            targetTree = [];
            Object.values(liveSports).map(x => {
                if (x.items.length) {
                    var count = x.items.length;
                    x.items.map((y, index) => {
                        y.weight = count - index;
                    });
                    targetTree = Array.concat(targetTree, x.items);
                }
            });
        }

        var saveData = Object.assign([], targetTree);
        saveData.map(x => {
            delete x.__selected;
        });


        API.post(selectedTreeType == 0 ? SportEndpoints.SaveTopLeagues : SportEndpoints.SaveLiveTopLeagues, saveData, 'fetching leagues', 'unable to fetch leagues').then(() => {
            Popper.pop('information', 'Data Saved', lang('%1, top leagues saved, web site data updated.', [selectedTreeType == 0 ? 'Prematch' : ' Live']));
        });

    };


    if (!topLeagues) return <></>;
    if (!topLeaguesLive) return <></>;
    if (!liveSports) return <></>;


    const tabButtons = [
        {
            name: 'prematch',
            title: 'Prematch'
        },
        {
            name: 'live',
            title: 'Live'
        }
    ];

    var liveSportsKeys = {};
    var liveSportTabs = [];
    topLeaguesLive.map(x => {
        if (!liveSportsKeys[x.sport]) {
            liveSportsKeys[x.sport] = true;
            liveSportTabs.push({
                name: x.sport,
                title: x.sport
            });
            liveSportTabs = liveSportTabs.sort((a, b) => {
                var aCategory = categories.find(y => y.name == a.name);
                var bCategory = categories.find(y => y.name == b.name);
                return aCategory.weight > bCategory.weight ? 1 : -1;
            });
        }
    });



    const searchLeagues = () => {
        Modal.open(<FluxModal title='Search' className={'search-leagues-modal'} footer={<flex>
            <Button title='Close' className={'success'} onClick={() => {
                Modal.close();
            }} />
        </flex>}>
            <SearchLeagues categories={categories} leagues={selectedTreeType == 0 ? topLeagues : topLeaguesLive} onChangeLeagues={(e) => {
                switch (selectedTreeType) {
                    case 0:
                        setTopLeagues(e);
                        break;
                    case 1:
                        setTopLeaguesLive(e);
                        break;
                }
            }} />
        </FluxModal>);
    };

    return <flex className='vertical gap-10 overflow-hidden'>
        <FluxResizableCard minLeftWidth={309}>
            <FluxCard title='Categories' className={'sports-tree-card-limit no-padding overflow-hidden'}>
                <SportsTree
                    data={categories}
                    regions leagues onRenderLeagueOptions={(league) => {
                        var targetTree = selectedTreeType == 0 ? topLeagues : topLeaguesLive;
                        var selected = targetTree.find(x => x.leagueId == league.id);

                        if (selected) return <ActionButton title='remove' icon='square-xmark' onClick={() => {
                            var copy = [...targetTree];
                            copy = copy.filter(x => x.leagueId != league.id);
                            if (selectedTreeType == 0) {
                                copy.map((y, index) => {
                                    y.weight = copy.length - index;
                                });
                                setTopLeagues(copy);
                            }
                            if (selectedTreeType == 1) setTopLeaguesLive(copy);
                        }
                        } />;

                        return <ActionButton title='add' icon='square-plus' onClick={() => {
                            var copy = [...targetTree];
                            copy.push({
                                league: league.name,
                                leagueId: league.id,
                                region: league.regionName,
                                regionId: league.region,
                                sport: league.sportName,
                                sportId: league.sport
                            });

                            if (selectedTreeType == 0) {
                                copy.map((y, index) => {
                                    y.weight = copy.length - index;
                                });
                                setTopLeagues(copy);
                            };
                            if (selectedTreeType == 1) {
                                setTopLeaguesLive(copy);
                            }
                        }
                        } />;
                    }} />
            </FluxCard>
            <div className='flex gap-5 fit align-start'>
                <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden' onChange={(index) => selectTreeType(index)}>
                    <FluxCard title='Top Leagues Prematch' buttons={<Button title='Save' onClick={() => save()} className='align-right success' />}>
                        <flex className='vertical gap-10'>
                            <flex className=''>
                                <Button title='Search Leagues' className={'success'} onClick={() => searchLeagues()} />
                            </flex>
                            <Table
                                model={topLeaguesModel}
                                data={topLeagues}
                                key={topLeagues}
                                className='fx-borders fx-shadow'
                                buttons={(row) => {
                                    var copy = { ...row };

                                    return <flex className='gap-10'>
                                        <Button title='Tags' className={'success'} onClick={() => {
                                            Modal.open(<FormModal title='Edit League Tages' onConfirm={(changed) => {
                                                var topLeaguesCopy = [...topLeagues];
                                                topLeaguesCopy.find(y => y.leagueId == row.leagueId).tags = changed.tags;
                                                setTopLeagues(topLeaguesCopy);
                                            }} onCancel={() => {
                                                var topLeaguesCopy = [...topLeagues];
                                                topLeaguesCopy.find(y => y.leagueId == row.leagueId).tags = copy.tags;
                                                setTopLeagues(topLeaguesCopy);
                                            }}
                                                data={row} model={{
                                                    fields: [
                                                        {
                                                            name: 'tags',
                                                            title: 'Tags',
                                                            type: InputTypes.Text
                                                        }
                                                    ]
                                                }}>
                                            </FormModal>);
                                        }} />

                                        <Button title='Remove' className={'warning'} onClick={() => {
                                            var copy = [...topLeagues];
                                            copy = copy.filter(x => x != row);
                                            copy.map((y, index) => {
                                                y.weight = copy.length - index;
                                            });
                                            setTopLeagues(copy);
                                        }} />
                                    </flex>;
                                }}
                                options={{
                                    draggable: {
                                        enabled: true
                                    }
                                }}
                                onDataChange={(row, index, value) => {
                                    value.map((y, index) => {
                                        y.weight = value.length - index;
                                    });
                                    setTopLeagues(Object.assign([], value));
                                }} />
                        </flex>
                    </FluxCard>
                    <FluxCard title='Top Leagues Live' buttons={<Button title='Save' onClick={() => save()} className='align-right success' />}>
                        {liveSportTabs.length > 0 && <React.Fragment>
                            <FluxTab className='gap-10 rounded-buttons' buttons={liveSportTabs} onChange={(index) => {
                                var selected = Object.values(liveSports).sort((a, b) => a.weight > b.weight ? 1 : -1)[index]?.name;
                                selectLiveSport(selected);
                                setLiveUpdateKey(Date.now());
                            }}>
                            </FluxTab>
                            <flex className='vertical gap-10'>
                                <flex className=''>
                                    <Button title='Search Leagues' className={'success'} onClick={() => searchLeagues()} />
                                </flex>
                                <Table
                                    model={topLeaguesLiveModel}
                                    data={liveSports[selectedLiveSport]?.items ?? []}
                                    key={liveUpdateKey}
                                    buttons={(row) => {
                                        var copy = { ...row };

                                        return <flex className='gap-10'>
                                            <Button title='Tags' className={'success'} onClick={() => {
                                                Modal.open(<FormModal title='Edit League Tages' onConfirm={(changed) => {
                                                    var leaguesLiveCopy = [...topLeaguesLive];
                                                    leaguesLiveCopy.find(y => y.leagueId == row.leagueId).tags = changed.tags;
                                                    setTopLeaguesLive(leaguesLiveCopy);
                                                }} onCancel={() => {
                                                    var leaguesLiveCopy = [...topLeaguesLive];
                                                    leaguesLiveCopy.find(y => y.leagueId == row.leagueId).tags = copy.tags;
                                                    setTopLeaguesLive(leaguesLiveCopy);
                                                }}
                                                    data={row} model={{
                                                        fields: [
                                                            {
                                                                name: 'tags',
                                                                title: 'Tags',
                                                                type: InputTypes.Text
                                                            }
                                                        ]
                                                    }}>
                                                </FormModal>);
                                            }} />
                                            <Button title='Remove' className={'warning'} onClick={() => {
                                                var copy = [...topLeaguesLive];
                                                copy = copy.filter(x => x != row);
                                                setTopLeaguesLive(copy);
                                            }} />
                                        </flex>;
                                    }}
                                    options={{
                                        draggable: {
                                            enabled: true
                                        }
                                    }}
                                    onDataChange={(row, index, value) => {
                                        var others = [...topLeaguesLive].filter(x => x.sport != selectedLiveSport);
                                        var temp = Array.concat(others, value);
                                        setTopLeaguesLive(temp);
                                        setLiveUpdateKey(Date.now());
                                    }} />

                            </flex>
                        </React.Fragment>
                        }
                    </FluxCard>
                </FluxTab>
            </div>
        </FluxResizableCard>

    </flex >;
};

SportTopLeagues.propTypes = {
    categories: PropTypes.object
};

