/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SportTicketHistory } from './SportTicketHistory';
import { lang } from '../../../lib/Localization/language';
import { FluxCard } from '../../card/FluxCard';
import { SportsTicketStatus, SportsTicketTypes } from '../../../lib/Enums/BettingEnums';
import { Currency } from '../../currency/Currency';
import { Table } from '../../table/Table';
import { EventScoreSummary } from './Score/EventScoreSummary';
import { convertLSportIdToBetradarSportId } from '../../../lib/Helpers/LsportConversationTools';
import { Country } from '../../icons/country/Country';
import { DateTime } from '../../datetime/DateTime';
import { appHistory } from '../../../..';
import { Modal } from '../../application/FluxApp';
import { Button } from '../../button/Button';
import { ManualCancel, ManualLost, ManualWin } from '../../../actions/TicketActions';
import { FluxModal } from '../../modal/FluxModal';
import { LiabilityEvent } from '../../../pages/Sports/BetMonitor/LiabilityEventMonitor/LiabilityEvent';
import { useSelector } from 'react-redux';
import { Icon } from '../../icon/Icon';

export const SelectionStatus = (props) => {
    const { status } = { ...props };
    switch (status) {
        case 0:
            return <span>{lang('Open')}</span>;
        case 2:
            return <span>{lang('Refund')}</span>;
        case 3:
            return <span>{lang('Lost')}</span>;
        case 4:
            return <span>{lang('Win')}</span>;
        case 5:
            return <span>{lang('W/Return')}</span>;
        case 6:
            return <span>{lang('L/Return')}</span>;
        case 8:
            return <span>{lang('Cashout')}</span>;
        case 9:
            return <span>{lang('Cancel')}</span>;
        default:
            return <span>{status}</span>;
    }
};


const IsActionEnabled = (user, ticketStatus, status) => {
    var accessGranted = user.UserPrivileges.AvailableActions.find(x => x == 'ManualTicketOperations');
    if (!accessGranted) return false;
    if (ticketStatus == 4) return false;
    var converted = SportsTicketStatus[status];
    var convertedTicketStatus = SportsTicketStatus[ticketStatus];
    if (converted == convertedTicketStatus) return false;
    // cash outs are not allowed
    if (ticketStatus == 3 && status != 4) return false;
    if (ticketStatus == 0) return true;


    switch (converted) {
        case 'Win':
            if (convertedTicketStatus == 'Lost') return true;
            if (convertedTicketStatus == 'Cancel') return true;
            if (convertedTicketStatus == 'Cashback') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            return false;
        case 'Lost':
            if (convertedTicketStatus == 'Win') return true;
            if (convertedTicketStatus == 'Cancel') return true;
            if (convertedTicketStatus == 'Cashback') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            if (convertedTicketStatus == 'Bet Cancel') return true;
            return false;
        case 'Open':
        default:
            return true;
    }
}

export const SportTicket = (props) => {
    const user = useSelector((state) => state.user.loginInformation);
    const [ticket, setTicket] = useState(props.ticket);
    const [displayMarketIds, toggleDisplayMarketIds] = useState(false);
    const [updateKey, setUpdateKey] = useState(0);
    const [isWrongOperator, toggleWrongOperator] = useState(false);
    const selections = props.selections;
    const scores = props.scores ?? {};
    const disableUpdate = props.disableUpdate ?? false;

    useEffect(() => {
        if (ticket && user) {
            if (ticket.operatorId != user.UserPrivileges.PointOfView) {
                toggleWrongOperator(true);
            }
        }
    }, []);

    var bets = JSON.parse(props.ticket.bets);

    bets.map(x => {
        var selection = selections.find(y => y.selectionId == x.Id);
        var score = scores[x.EventExternalId];
        if (selection) {
            x.Status = selection.status;
        }
        if (score) x.Score = JSON.parse(score);
    });

    return <flex className='vertical gap-20'>
        <SportTicketHistory history={props.history} currency={props.ticket.currency} ticket={ticket} onChange={props.onChange} />
        <FluxCard title={
            <React.Fragment>
                <span>{SportsTicketTypes[ticket.ticketType]}</span>
                <flex className='gap-20 align-right'>
                    {ticket.win > 0 &&
                        <flex className='gap-5'>
                            <label>{lang('Return')}</label>
                            <span><Currency amount={ticket.win} sign={ticket.currency} /></span>
                        </flex>
                    }
                    <span>{SportsTicketStatus[ticket.status]}</span>
                </flex>
            </React.Fragment>
        }>
            <flex className='vertical gap-20 fit-children'>
                <flex className='columns-4 gap-20'>
                    <flex className='vertical'>
                        <label>{lang('Entry Date')}</label>
                        <DateTime date={ticket.entryDate} time />
                    </flex>
                    <flex className='vertical'>
                        <label>{lang('Stake')}</label>
                        <span><Currency amount={ticket.bet} sign={ticket.currency} /></span>
                    </flex>
                    <flex className='vertical'>
                        <label>{lang('Odds')}</label>
                        <span>{ticket.odds.toFixed(2)}x</span>
                    </flex>
                    {
                        !ticket.ticketBonus && <flex className='vertical'>
                            <label>{lang('Possible Win')}</label>
                            <span><Currency amount={ticket.possibleWin} sign={ticket.currency} /></span>
                        </flex>
                    }
                    {
                        ticket.ticketBonus && <flex className='vertical'>
                            <label>{lang('Possible Win')}</label>
                            <span><Currency amount={ticket.possibleWin - ticket.ticketBonusAmount} sign={ticket.currency} /></span>
                        </flex>
                    }
                    {
                        ticket.ticketBonus && <flex className='vertical'>
                            <label>{lang('Accumulator bonus')}</label>
                            <flex className='gap-5'>
                                <span><Currency amount={ticket.ticketBonusAmount} sign={ticket.currency} /></span>
                                <span>%{ticket.ticketBonusPercentage}</span>
                            </flex>
                        </flex>
                    }
                    <flex className='vertical'>
                        <label>{lang('Win')}</label>
                        <span><Currency amount={ticket.possibleWin} sign={ticket.currency} /></span>
                    </flex>
                    {
                        !disableUpdate && <React.Fragment>
                            {isWrongOperator && <flex className='gap-10 justify-content-end'>
                                <Button title='Change your POV for manual update' className={'disabled'} onClick={() => { }} />
                            </flex>}
                            {!isWrongOperator && <flex className='gap-10 justify-content-end'>
                                <Button title='Win' className={IsActionEnabled(user, ticket.status, 1) ? ' success' : 'disabled'} onClick={() => {
                                    ManualWin(ticket, (newTicket) => {
                                        setTicket(newTicket);
                                        if (props.onChange) {
                                            props.onChange(newTicket);
                                        }
                                    });
                                }} />
                                <Button title='Lost' className={IsActionEnabled(user, ticket.status, 2) ? ' warning' : 'disabled'} onClick={() => {
                                    ManualLost(ticket, (newTicket) => {
                                        setTicket(newTicket);
                                        if (props.onChange) {
                                            props.onChange(newTicket);
                                        }
                                    });
                                }} />
                                <Button title='Cancel' className={IsActionEnabled(user, ticket.status, 4) ? ' warning' : 'disabled'} onClick={() => {
                                    ManualCancel(ticket, (newTicket) => {
                                        setTicket(newTicket);
                                        if (props.onChange) {
                                            props.onChange(newTicket);
                                        }
                                    });
                                }} />
                            </flex>}
                        </React.Fragment>
                    }
                </flex>

                <Table className='fx-borders fx-shadow' model={{
                    fields: [
                        {
                            name: 'Date',
                            title: 'Event Date',
                            render: (val, row) => {
                                return <flex className='vertical'>
                                    <DateTime date={val} time vertical />
                                </flex>;
                            }
                        },
                        {
                            name: 'EventId',
                            title: 'Event Id',
                            render: (val, row) => {
                                return <flex className='vertical'>
                                    <a href='' onClick={(e) => {
                                        e.preventDefault();
                                        Modal.open(<FluxModal title={lang('Event #%1', [row.EventExternalId])} className='modal-large' footer={<Button title='Close' onClick={() => Modal.close()} />}>
                                            <LiabilityEvent id={row.EventExternalId}
                                                key={row.EventExternalId}
                                                popupMode={true}
                                                onClose={() => {
                                                    appHistory.push(`/sports/tickets`);
                                                    Modal.close();
                                                }} />
                                        </FluxModal>);
                                    }}>
                                        <span>{row.EventExternalId}</span>
                                    </a>
                                </flex>;
                            }
                        },
                        {
                            name: 'Sport',
                            title: 'Sport',
                            render: (val, row) => {
                                return <flex className='gap-10'>
                                    <Country name={row.Region} circular />
                                    <flex className='vertical'>
                                        <span>{val}</span>
                                        <span>{row.Region}</span>
                                    </flex>
                                </flex>;
                            }
                        },
                        {
                            name: 'League',
                            title: 'League'
                        },
                        {
                            name: 'EventName',
                            title: 'Event',
                            render: (val) => {
                                var vs = val.split(' vs ');
                                if (vs.length == 2) {
                                    return <flex className='vertical align-items-center gap-1'>
                                        <span className='width-100 text-align-center'>{vs[0]}</span>
                                        <span className='bold'>{lang('vs')}</span>
                                        <span className='width-100 text-align-center'>{vs[1]}</span>
                                    </flex>;
                                }

                                return val;
                            }
                        },
                        {
                            name: 'Score',
                            title: 'Score',
                            render: (val, row) => {
                                return <EventScoreSummary event={{ sport: { id: convertLSportIdToBetradarSportId(row.SportId) } }} statistics={val} />;
                            }
                        },
                        {
                            name: 'MarketName',
                            title: 'Market',
                            renderHeader: () => {
                                return <flex className='center'>
                                    <span class='center'>Market</span>
                                    <Icon icon={'info-circle'} title='Display Market Ids' onClick={() => {
                                        toggleDisplayMarketIds(!displayMarketIds);
                                        setUpdateKey(Date.now());
                                    }}></Icon>
                                </flex>;
                            },
                            render: (val, row) => {
                                if (displayMarketIds) {
                                    return <flex class='vertical'>
                                        <span>{val}</span>
                                        <span>Market Id: {row.MarketId}</span>
                                    </flex>;

                                }
                                return val;
                            }
                        },
                        {
                            name: 'St',
                            title: 'Selection',
                            render: (val, row) => {
                                if (val) return val;
                                return `${row.SelectionName} ${row.Specifiers}`;
                            }
                        },
                        {
                            name: 'Price',
                            title: 'Price'
                        },
                        {
                            name: 'Status',
                            title: 'Status',
                            render: (val) => {
                                return <SelectionStatus status={val} />;
                            }
                        }
                    ],
                    onRenderClassName: (row, className) => {
                        switch (row.Status) {
                            case 0:
                                return className;
                            case 3:
                                return 'bet-selection-lost';
                            case 4:
                                return 'bet-selection-win';
                            case 8:
                                return 'bet-selection-cashout';
                            case 9:
                                return 'bet-selection-cancel';
                        }
                        return className;
                    }
                }}
                    data={bets}
                    key={updateKey}
                />
            </flex>
        </FluxCard>
    </flex>;
};

SportTicket.propTypes = {
    history: PropTypes.object,
    ticket: PropTypes.object,
    selections: PropTypes.array,
    scores: PropTypes.object,
    onChange: PropTypes.func
};