/* eslint-disable react/display-name */
import React from 'react';
import { addUser, changeUserPassword, editUser } from '../../../actions/UserActions';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { POSEndpoints } from '../../../lib/API/Endpoints';
import { depositPOSUser, setupPOSBindings, withdrawPOSUser } from '../../../actions/POSTransactionActions';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { Tip } from '../../../components/tip/Tip';


export const SearchPOSUsers = () => {
    const model = {
        fields: [
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text,
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'Phone',
                type: InputTypes.Text
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool,
                value: true
            }
        ]
    };

    const resultModel = {
        fields: [
            {
                name: 'Id'
            },
            {
                name: 'Username',
                display: 'Username'
            },
            {
                name: 'Email',
                type: InputTypes.Text
            },
            {
                name: 'Name',
                type: InputTypes.Text
            },
            {
                name: 'Surname',
                type: InputTypes.Text
            },
            {
                name: 'AuthType',
                title: 'Authentication',
                render: (val) => {
                    switch (val) {
                        case 1:
                            return '2FA Enabled';
                        default: return 'Normal';
                    }
                }
            },
            {
                name: 'Phone',
            },
            {
                name: 'Currency',
            },

            {
                name: 'Balance',
            },
            {
                name: 'Enabled',
                type: InputTypes.Bool
            }
        ]
    };


    const onActionClicked = (action, user, context) => {
        var data = context.tableContext.getData();
        switch (action.type) {
            case ActionTypes.EditMyUser:
                editUser(user.Id, (result, data) => {
                    user.Enabled = data.Enabled;
                });
                break;
            case ActionTypes.ChangeMyUserPassword:
                changeUserPassword(user);
                break;
            case ActionTypes.POSDeposit:
                depositPOSUser(user, (a) => {
                    data.find(x => x.Id == user.Id).Balance = a.balanceAfter;
                    context.tableContext.setData(data);
                });
                break;
            case ActionTypes.POSWithdraw:
                withdrawPOSUser(user, (a) => {
                    data.find(x => x.Id == user.Id).Balance = a.balanceAfter;
                    context.tableContext.setData(data);
                });
                break;
            case ActionTypes.POSProviderBindings:
                setupPOSBindings(user, (bindings) => {
                    data.find(x => x.Id == user.Id).Bindings = bindings;
                    context.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    return <flex className='vertical gap-10'>
        <FluxCard>
            <flex className='gap-20'>
                <Button className='align-left success' title='Add new' onClick={() => addUser(() => {

                })} />
                <Tip className='align-right' tip='Create User in POS USER Role using group types.' />
            </flex>
        </FluxCard>
        <FluxSearch model={model} resultModel={resultModel} endpoint={POSEndpoints.SearchUsers} searchOnStart actions={ActionTypes.POSUsers} onActionClicked={onActionClicked.bind(this)} />
    </flex>;
};