/* eslint-disable react/display-name */
import React from 'react';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { openProfile } from '../../../lib/Helpers/NavigationHelper';

const model = {
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'UserId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Created',
            title: 'Date',
            max: 19,
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Ip',
            title: 'IP'
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'IP',
            title: 'IP'
        },
        {
            name: 'Username1',
            title: 'Username',
            render: (val, row) => {
                return openProfile(val,row.PlayerId1);
            }
        },
        {
            name: 'LastLogin1',
            title: 'Last Login',
            type: InputTypes.DateTime
        },
        {
            name: 'Username2',
            title: 'Username',
            render: (val, row) => {
                return openProfile(val,row.PlayerId2);
            }
        },
        {
            name: 'LastLogin2',
            title: 'Last Login',
            type: InputTypes.DateTime
        }
    ]
};

export const PlayersWithSameIp = () => {
    return <FluxSearch
        model={model}
        endpoint={PlayerEndpoints.SearchSameIpPlayers}
        resultModel={resultModel}
    />;
};
