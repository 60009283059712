import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from '../../../../../../lib/API/Api';
import { RiskLimitEndpoints, SportEndpoints } from '../../../../../../lib/API/Endpoints';
import { lang } from '../../../../../../lib/Localization/language';
import { Modal } from '../../../../../application/FluxApp';
import { Button } from '../../../../../button/Button';
import { ActionButton, FluxCard } from '../../../../../card/FluxCard';
import { FluxForm } from '../../../../../form/FluxForm';
import { ConfirmationModal } from '../../../../../modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../../notification/FluxNotice';
import { Popper } from '../../../../../notification/FluxPopper';
import { FluxTab } from '../../../../../tab/FluxTab';
import { SportsTree } from '../../../../SportsTree/SportsTree';
import { ShowAddNewTemplateModal, customTemplateModel, templateViewModel } from '../RiskTemplates/RiskTemplates';
import { FluxResizableCard } from '../../../../../resizableCard/FluxResizableCard';


export const LimitEditor = (props) => {
    const user = useSelector((state) => state.user);
    const { type, id, name, sportId, regionId, leagueId, eventId } = { ...props };

    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [customTemplate, setCustomTemplate] = useState(null);

    const [updateKey, setUpdateKey] = useState(0);

    useEffect(() => {
        if (sportId) {
            API.post(RiskLimitEndpoints.Templates(user.loginInformation.UserPrivileges.PointOfView)).then((templatesResult) => {
                setTemplates(templatesResult.result);
                API.post(RiskLimitEndpoints.GetTemplateWithAll(user.loginInformation.UserPrivileges.PointOfView, type, id),
                    {
                        sportId: sportId,
                        regionId: regionId,
                        leagueId: leagueId,
                        eventId: eventId
                    }).then((result) => {
                        if (result.result.customTemplate) {
                            var copy = templatesResult.result;
                            copy = [result.result].concat(copy);
                            setTemplates(copy);
                        }
                        setTemplate(result.result);
                    });
            });
        }
    }, sportId);

    if (!template) return <></>;
    return <FluxCard title='Limit Editor' className='gap-10' buttons={<flex className='align-right gap-5'>
        <span>{type}</span>
        <span>{id}</span>
    </flex>}>
        <flex className='vertical gap-10'>
            <flex className='vertical gap-10'>
                {template.customTemplate && <FluxNotice type='information' title='Custom template' description={lang('This entity is using template a custom template!')} />}
                {!template.customTemplate && <FluxNotice type='information' title='Entity using template' description={lang('This entity is using template #%1, "%2"', [template.id, template.name])} />}
                <FluxCard>
                    <flex className='gap-10'>
                        <flex className='gap-10 align-items-center'>
                            <span>{lang('Select from template')}</span>
                            <select value={template?.id} onChangeCapture={(e) => {
                                setTemplate(templates.find(x => x.id == parseFloat(e.target.value)));
                                setUpdateKey(Date.now());
                            }}>
                                {templates.map(x => {
                                    return <option key={x.id} value={x.id}>{x.name}</option>;
                                })}
                            </select>
                            {!template.customTemplate && <Button title='Assign this template' className={'success'} onClick={() => {
                                Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                                    API.post(RiskLimitEndpoints.AssignTemplate(user.loginInformation.UserPrivileges.PointOfView, type, id, template.id),
                                        {
                                            sportId: sportId,
                                            regionId: regionId,
                                            leagueId: leagueId,
                                            eventId: eventId
                                        }).then(() => {
                                            setTemplate(templates.find(x => x.id == template?.id));
                                        });
                                }}>
                                    <FluxNotice type='information' title='Confirm assigning template.' description={'The template will be assigned to the entity will affect changes on the risk system.'} />
                                </ConfirmationModal>);
                            }} />
                            }
                            {!template.customTemplate &&
                                <flex className='align-right gap-5'>
                                    <Button title='Customize limits' className={'warning'} onClick={() => {
                                        var customTemplate = templates.find(x => x.customTemplate);
                                        var target = template;
                                        if (customTemplate != null) {
                                            target = customTemplate;
                                        }

                                        Object.keys(template).map(x => {
                                            if (x !== 'id' && x !== 'name' && x !== 'active') {
                                                target[x] = template[x];
                                            }
                                        });
                                        var copy = JSON.parse(JSON.stringify(target));
                                        copy.id = customTemplate ? customTemplate.id : 0;
                                        copy.name = `custom_${name}`;
                                        copy.customTemplate = true;
                                        if (!copy.stakeLimit) {
                                            copy.stakeLimit = 0;
                                        }
                                        if (!copy.liabilityLimit) {
                                            copy.liabilityLimit = 0;
                                        }
                                        if (!copy.maxPayout) {
                                            copy.maxPayout = 0;
                                        }
                                        if (!copy.investigateTicketOverMultiplier) {
                                            copy.investigateTicketOverMultiplier = 0;
                                        }
                                        if (!customTemplate) {
                                            setTemplates([copy].concat(templates));
                                        }
                                        setTemplate(copy);
                                        setUpdateKey(Date.now());
                                    }} />
                                </flex>
                            }
                        </flex>
                    </flex>
                </FluxCard>
            </flex>
            <FluxCard className={'no-padding'} title={template?.name} key={updateKey} buttons={!template?.customTemplate ? <flex className='align-right'>
                <ActionButton title='Edit this template' icon='pen' onClick={() => {
                    ShowAddNewTemplateModal(template, (savedData) => {
                        setTemplate(savedData);
                        setUpdateKey(Date.now());
                    });
                }} />
            </flex> : null}>
                {template && <FluxForm model={!template.customTemplate ? templateViewModel : customTemplateModel} data={template ?? {
                    name: `custom_${type}_${id}`,
                }} key={updateKey} buttons={template?.customTemplate ? <flex className='gap-5 padding'><Button title='Save' className={'success'} onClick={() => {
                    Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
                        customTemplate.name = `custom_${name}_${id}`;
                        var tempDatas = Object.assign({}, customTemplate);
                        if (!tempDatas.stakeLimit) {
                            tempDatas.stakeLimit = 0;
                        }
                        if (!tempDatas.liabilityLimit) {
                            tempDatas.liabilityLimit = 0;
                        }
                        if (!tempDatas.maxPayout) {
                            tempDatas.maxPayout = 0;
                        }
                        if (!tempDatas.investigateTicketOverMultiplier) {
                            tempDatas.investigateTicketOverMultiplier = 0;
                        }
                        tempDatas.sportId = sportId;
                        tempDatas.regionId = regionId;
                        tempDatas.leagueId = leagueId;
                        tempDatas.eventId = eventId;

                        API.post(RiskLimitEndpoints.SaveTemplate(user.loginInformation.UserPrivileges.PointOfView), tempDatas).then((result) => {
                            setTemplate(result.result);
                            Popper.pop('information', 'Limits saved!', 'Custom limits for entity is saved!');
                            Modal.close();
                        });
                    }}>
                        <FluxNotice type='information' title='Save custom limits?' description={'This limits will be customized for the selected entity!'} />
                    </ConfirmationModal>)
                }} /></flex> : null} onChange={(data) => {
                    setCustomTemplate(data);
                }} />
                }
            </FluxCard>
        </flex>
    </FluxCard>;
};

export const RiskLimits = () => {
    const [category, selectCategory] = useState(null);
    const [region, selectRegion] = useState(null);
    const [league, selectLeague] = useState(null);
    const [event, selectEvent] = useState(null);
    const [selectedIndex, selectIndex] = useState(0);

    const [targetLimit, edit] = useState('sport');

    useEffect(() => {
        if (!category) return;
        selectRegion(null);
        selectLeague(null);
        selectEvent(null);
    }, [category]);

    useEffect(() => {
        if (!region) return;
        selectLeague(null);
        selectEvent(null);
    }, [region]);

    useEffect(() => {
        if (!league) return;
        selectEvent(null);
    }, [league]);

    useEffect(() => {
        if (!event) return;
    }, [event]);



    var tabButtons = null;

    if (category) {
        tabButtons = [];
        tabButtons.push({
            data: category,
            title: category.name,
        });
    }

    if (region) {
        tabButtons.push({
            data: region,
            title: region.name,
        });
    }

    if (league) {
        tabButtons.push({
            data: league,
            title: league.name,
        });
    }

    if (event) {
        tabButtons.push({
            data: event,
            title: event.name,
        });
    }
    const renderEditingView = () => {
        var id = 0;
        var key = targetLimit;
        var name = key;
        switch (targetLimit) {
            case 'sport':
                id = category.id;
                name = category.name;
                break;
            case 'region':
                if (!region) return;
                key = `region_${category.id}`;
                name = `${category.name}_${region.name}`;
                id = region.id;
                break;
            case 'league':
                if (!league) return;
                id = league.id;
                name = `${category.name}_${region?.name}_${league?.name}`;
                break;
            case 'event':
                if (!event) return;
                id = event.id;
                name = `${category?.name}_${region?.name}_${league?.name}_${event?.name}_${event?.id}`;
                break;

        }
        return <LimitEditor type={key} id={id} key={key + '_' + id} name={name}
            sportId={targetLimit == 'sport' || targetLimit == 'region' || targetLimit == 'league' || targetLimit == 'event' ? category?.id : null}
            regionId={targetLimit == 'region' || targetLimit == 'league' || targetLimit == 'event' ? region?.id : null}
            leagueId={targetLimit == 'league' || targetLimit == 'event' ? league?.id : null}
            eventId={targetLimit == 'event' ? event?.id : null} />;
    };
    return <flex className='vertical gap-10 overflow-hidden'>
        <FluxResizableCard minLeftWidth={309}>
            <FluxCard title='Categories' className={'sports-tree-card-limit no-padding overflow-hidden'}>
                <SportsTree
                    regions leagues events
                    onCategorySelected={(c) => {
                        edit('sport');
                        selectIndex(0);
                        selectRegion(null);
                        selectLeague(null);
                        selectEvent(null);
                        selectCategory(c);
                    }}
                    onRegionSelected={(c, r) => {
                        edit('region');
                        selectIndex(1);
                        selectRegion(r);
                        selectLeague(null);
                        selectEvent(null);
                    }}
                    onLeagueSelected={(l) => {
                        edit('league');
                        selectIndex(2);
                        selectLeague(l);
                        selectEvent(null);
                    }}
                    onEventSelected={(e) => {
                        edit('event');
                        selectIndex(3);
                        selectEvent(e);
                    }}
                />
            </FluxCard>
            <FluxCard title='Limits' className={'overflow-hidden'}>
                {tabButtons && <FluxTab selected={selectedIndex} buttons={tabButtons} className='gap-10 fx-radius rounded-buttons' onChange={(index) => {
                    switch (index) {
                        case 0:
                            edit('sport');
                            break;
                        case 1:
                            edit('region');
                            break;
                        case 2:
                            edit('league');
                            break;
                        case 3:
                            edit('event');
                            break;
                    }
                }}>
                    {renderEditingView()}
                </FluxTab>}
            </FluxCard>
        </FluxResizableCard>

    </flex>;
};