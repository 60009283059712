import React, { useEffect, useState } from 'react';
import { FluxTab } from '../../../tab/FluxTab';
import { ActionButton, FluxCard } from '../../../card/FluxCard';
import { API } from '../../../../../v2/Lib/Api/Api';

import { EventStatus } from '../../../../lib/Enums/BettingEnums';
import { TraderEndpoints } from '../../../../lib/API/Endpoints';
import { EventStatistics, getScore, Team } from '../../SportEvent/Event';
import { convertLSportIdToBetradarSportId } from '../../../../lib/Helpers/LsportConversationTools';
import { EventScoreSummary } from '../../Ticket/Score/EventScoreSummary';
import { MarketView } from './MarketView';
import { DateHelpers } from '../../../../lib/Helpers/DateHelpers';
import { Button } from '../../../button/Button';


const MarketViews = {
    Selections: 'Selections',
    SelectionWithBets: 'SelectionWithBets'
};


export const EventView = (props) => {
    const { event, onClose } = { ...props };

    const [eventData, setEvent] = useState(null);

    const [view, setView] = useState(MarketViews.SelectionWithBets);

    const score = eventData?.liveScore;
    var convertedScore = score ? JSON.parse(score) : {};


    let tabButtons = [
        {
            title: 'Markets with bets'
        },
        {
            title: 'All Markets'
        }
    ];

    useEffect(() => {
        API.get(TraderEndpoints.GetEventById(event.id)).then((result) => {
            setEvent(result.result);
        });
    }, []);


    const renderDate = () => {
        if (!eventData) return;
        var date = new Date(eventData.date + 'Z');

        let clock_MatchTime = '';
        if (Object.keys(convertedScore).length) {
            try {
                let seconds = parseFloat(convertedScore.Scoreboard?.Time);
                let minutes = seconds / 60; // Convert seconds to minutes

                let totalMinutes = Math.floor(minutes);
                let totalSeconds = Math.floor(seconds % 60); // Get the remaining seconds

                if (seconds < 60) {
                    if (seconds < 0) seconds = 0;

                    totalMinutes = 0;
                    totalSeconds = Math.floor(seconds);
                }

                clock_MatchTime = `${String(totalMinutes).padStart(2, '0')}:${String(totalSeconds).padStart(2, '0')}`;
            } catch (err) {
                //
            }
        }


        return <flex className='vertical gap-5 align-items-center justify-content-center'>
            <label className='medium'>{EventStatus[eventData.status]}</label>
            <flex className='gap-5'>
                <span>{date.toLocaleDateString('en-Gb', { hour12: false })}</span>
            </flex>
            <flex className='vertical align-items-center justify-content-center'>
                <label className='medium'>{DateHelpers.getTime(date)}</label>
                <span>{clock_MatchTime}</span>
            </flex>
        </flex>;
    };

    if (!eventData) return <></>;

    return <flex className='gap-10 event-view'>
        {
            eventData && <EventStatistics event={{
                sport: eventData.sportId,
                liveScore: eventData.liveScore
            }} />
        }
        <flex className='vertical gap-10'>
            <FluxCard title={eventData.name} buttons={<flex className='align-right'>
                <ActionButton title='Close' icon='times' onClick={() => {
                    onClose();
                }} /></flex>}>


                {eventData && <flex className='vertical gap-10'>
                    <flex className='gap-20 justify-content-center'>
                        <flex className='vertical'>
                            <Team home event={eventData} score={getScore(convertedScore.Scoreboard?.Results, 1)} />
                        </flex>
                        <flex className='vertical gap-5 align-items-center justify-content-center'>
                            {renderDate()}
                            <EventScoreSummary
                                onlyPeriods
                                event={{
                                    sport: {
                                        id: convertLSportIdToBetradarSportId(event?.sport)
                                    }
                                }} statistics={convertedScore} />
                        </flex>
                        <flex className='vertical'>
                            <Team away event={eventData} score={getScore(convertedScore.Scoreboard?.Results, 2)} />
                        </flex>
                    </flex>
                    <flex className='gap-10 center'>
                        <Button className='warning' title='Bet Stop' />
                        <Button className='warning' title='Disable auto settlements' />
                    </flex>
                </flex>
                }
            </FluxCard>
            <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden' onChange={(index) => {
                switch (index) {
                    case 0:
                        setView(MarketViews.SelectionWithBets);
                        break;
                    case 1:
                        setView(MarketViews.Selections);
                        break;
                }
            }}>
                {view == MarketViews.SelectionWithBets && <MarketView event={eventData} withBets />}
                {view == MarketViews.Selections && <MarketView event={eventData} />}
            </FluxTab>
        </flex>
    </flex>;
};