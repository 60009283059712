import './_event-liability.scss';


import React, { useEffect, useState } from 'react';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { API } from '../../../../lib/API/Api';
import { RiskLimitEndpoints, SportRiskMonitorEndpoints } from '../../../../lib/API/Endpoints';
import { useSelector } from 'react-redux';
import { Table } from '../../../../components/table/Table';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { lang } from '../../../../lib/Localization/language';
import { Button } from '../../../../components/button/Button';
import { SearchPlayerBetHistory } from '../../../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { getMatchTime } from '../BetTicker/BetTicker';
import { ShowAddNewTemplateModal } from '../../../../components/sports/RiskManagement/RiskLimits/Components/RiskTemplates/RiskTemplates';
import { gotoProfile } from '../../../../lib/Helpers/NavigationHelper';

const marketsModel = {
    fields: [
        {
            name: 'market-id',
            title: 'Id',
            render: (val, row) => row.id
        },
        {
            name: 'marketName',
            title: 'Name'
        },
        {
            name: 'tickets',
            title: 'Tickets',
            render: (val, row) => {
                return row.volume.totalSlips;
            }
        },
        {
            name: 'sll',
            title: 'SLL',
            render: (val, row) => {
                return row.volume.sll;
            }
        },
        {
            name: 'amount',
            title: 'Stake',
            render: (val, row) => {
                return row.volume.amount;
            }
        },
        {
            name: 'liability',
            title: 'Liability',
            render: (val, row) => {
                return row.volume.liability;
            }
        },
        {
            name: 'paid',
            title: 'Paid',
            render: (val, row) => {
                return row.volume.paid;
            }
        },
        {
            name: 'profit',
            title: 'Profit',
            render: (val, row) => {
                return row.volume.profit;
            }
        },
        {
            name: 'selections',
            title: 'Selections',
            render: (val, row) => {
                return <flex className='vertical'>
                    {Object.keys(row.selections).map(x => {
                        return <flex className='gap-5' key={x}>
                            <span>{x}</span>
                            <span>{row.selections[x].toFixed(2)}</span>
                        </flex>;
                    })}
                </flex>;
            }
        }
    ]
};


const SearchPlayerVolumes = (props) => {
    const { operatorId, id } = { ...props };
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        API.post(SportRiskMonitorEndpoints.Players, {
            operatorId: operatorId,
            eventId: id
        }, 'please wait', 'unable to load event').then((result) => {
            setPlayers(result.result);
        });
    }, []);



    return <FluxCard className={'no-padding'}><Table data={players} model={{
        fields: [
            {
                name: 'Username',
                title: 'Username',
                render: (val, row) => {
                    return openProfile(row.player?.split(':')[1], row.player?.split(':')[0]);
                }
            },
            {
                name: 'tickets',
                title: 'Tickets',
                render: (val, row) => {
                    return row.volume.totalSlips;
                }
            },
            {
                name: 'stake',
                title: 'Stake',
                render: (val, row) => {
                    return row.volume.amount;
                }
            },
            {
                name: 'stake',
                title: 'Liability',
                render: (val, row) => {
                    return row.volume.liability;
                }
            }
        ]
    }} /></FluxCard>;
};

export const LiabilityEvent = (props) => {
    const { id, onClose, popupMode = false } = { ...props };
    const operatorId = useSelector((state) => state.user).loginInformation.UserPrivileges.PointOfView;
    const [event, setEvent] = useState(null);

    const customLiabilityRef = React.createRef();

    useEffect(() => {
        API.post(SportRiskMonitorEndpoints.Event, {
            operatorId: operatorId,
            eventId: id
        }, 'please wait', 'unable to load event').then((result) => {
            setEvent(result.result);
        });
    }, []);


    var tabButtons = [
        {
            name: 'tickets',
            title: 'Tickets '
        },
        {
            name: 'markets',
            title: 'Markets'
        }
    ];

    var homeScore = '';
    var awayScore = '';

    var time = '';
    var score = JSON.parse(event?.score ?? '{}');
    var title = event?.eventName;

    if (score?.Results) {
        homeScore = score.Results.find(x => x.Position == '1').Value;
        awayScore = score.Results.find(x => x.Position == '2').Value;
        time = getMatchTime(score.Time);

        title = `${title} [${homeScore}:${awayScore}] -  ${time}`;
    }




    return <FluxCard title={!event ? 'Event is expired or loading' : title} className={'event-liability overflow-hidden align-self-stretch gap-10'} buttons={<flex className='align-right'><ActionButton title='close' icon='times' onClick={() => onClose()} /></flex>}>
        {!event && popupMode ?
            <flex>Event is expired</flex>
            :
            <flex className='vertical gap-10'>
                <FluxCard title='Summary'>
                    <flex className='vertical gap-10'>
                        <flex className='gap-10 columns-2'>
                            <span className='bold'>{lang('Liability')}</span>
                            <span>{event?.volume.liability.toFixed(2)}</span>
                        </flex>
                        <flex className='gap-10 columns-2 hidden'>
                            <span className='bold'>{lang('Set Liability')}</span>
                            <flex className='gap-5'>
                                <input type='number' ref={customLiabilityRef} defaultValue={event?.configuredLiability} />
                                <Button className='align-right clickable' onClick={() => {
                                    API.post(RiskLimitEndpoints.GetAllTemplates(operatorId)).then((r) => {
                                        try {
                                            ShowAddNewTemplateModal(r.result.find(x => x.id == event?.templateId), () => { });
                                        } catch (err) {
                                            //
                                        }
                                    });
                                }}>
                                    <span>{lang('Edit template')}</span>
                                </Button>
                            </flex>
                        </flex>
                    </flex>
                </FluxCard>
                <div>
                    {popupMode ?
                        <SearchPlayerBetHistory operatorId={operatorId} eventId={event?.id} filters={false} />
                        : <FluxTab buttons={tabButtons} className='gap-10 fx-radius rounded-buttons radius justify-content-center overflow-hidden'>
                            <SearchPlayerBetHistory operatorId={operatorId} eventId={event?.id} filters={false} />
                            <FluxCard className={'no-padding'}>
                                <Table model={marketsModel} data={Object.values(event?.markets ?? [])} />
                            </FluxCard>
                        </FluxTab>
                    }
                </div>
            </flex>
        }
    </FluxCard >;
};